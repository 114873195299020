import React, { useContext, useState } from "react";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import logo from '../../Assets/Spyder Logo.png'
import '../../Css/Header.css';
import { IoMenu } from "react-icons/io5";
import { LiaHomeSolid } from "react-icons/lia";
import HoverMenu from "../../Components/HoverMenu";
import Drawer from 'react-modern-drawer';
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthProvider";
import { LoginPopup } from "../LoginPopup";
import userIcon from "../../Assets/user.png";
import useModal from "../../hooks/useModal";
import { useCart } from "../../context/CartProvider";
import { FaCaretRight, FaRegUser } from "react-icons/fa";
import homeIcon from "../../Assets/home.png";
import customerIcon from "../../Assets/customer.png";
import moneyIcon from "../../Assets/money.png";
import homeActiveIcon from "../../Assets/home-active.png";
import customerActiveIcon from "../../Assets/customer-active.png";
import moneyActiveIcon from "../../Assets/money-active.png";
import { PageContext } from "../../context/PageProvider";

const ServiceLink = React.forwardRef((props, ref) => (
    <li ref={ref} {...props}>Service</li>
));
const IndustriesLink = React.forwardRef((props, ref) => (
    <li ref={ref} {...props}>Industries</li>
));

const MenuItemWithLink = ({ to, children }) => (
    <Link to={to} onClick={() => {
        window.scrollTo(0, 0);
    }}>
        <MenuItem>{children}</MenuItem>
    </Link>
);

const Header = () => {
    const { user, logOut } = UserAuth();
    const [isOpen, setIsOpen] = React.useState(false);
    const [mobileOpenedSubmenu, setMobileOpenedSubmenu] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showMobileAuthLinks, setShowMobileAuthLinks] = useState(false);
    const { activeMobileTab, setActiveMobileTab } = useContext(PageContext);
    const { setModalVisibility, setIsCartSubmit } = useModal();
    const mobileLinkStyles = { textDecoration: 'none', color: '#000000' };
    const ProfileLink = React.forwardRef((props, ref) => (
        <li ref={ref} {...props} className="profile-container">
            <div>{user.first_name ? user.first_name : 'Guest'}</div>
            <img className="profile-icon" src={userIcon} alt="" />
        </li>

    ));
    const { setIsCartOpen } = useCart();
    const navigate = useNavigate();

    const toggleMobileAuthLinks = () => {
        setShowMobileAuthLinks(!showMobileAuthLinks);
    }
    const onMobileSubmenuClick = React.useCallback((key) => {
        if (key === mobileOpenedSubmenu) {
            setMobileOpenedSubmenu('');
            return;
        }
        setMobileOpenedSubmenu(key);
    }, [mobileOpenedSubmenu]);

    const toggleDrawer = React.useCallback(() => {
        setIsOpen((prevState) => !prevState)
    }, []);

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div id='header' className='header'>
                <button onClick={toggleDrawer} className="mobile_nav_menu_button"><IoMenu /></button>
                <img src={logo} alt='logo' className='logo' onClick={() => {
                    setActiveMobileTab("home");
                    window.scrollTo(0, 0);
                    navigate('/')
                }} />
                <ul className='header_menu1' onClick={() => { setIsCartOpen(false) }}>
                    <li><Link to="/" onClick={() => { window.scrollTo(0, 0) }}>Home</Link></li>
                    <HoverMenu MenuButton={ServiceLink}>
                        <MenuItemWithLink to="/services/personal-tax">Personal Tax</MenuItemWithLink>
                        <MenuItemWithLink to="/services/business-tax">Business Tax</MenuItemWithLink>
                        <MenuItemWithLink to="/services/ad-hoc-accounting">Ad- Hoc Accounting</MenuItemWithLink>
                        <MenuItemWithLink to='/services/bookkeeping'>Bookkeeping</MenuItemWithLink>
                        <MenuItemWithLink to='/services/fractional-cfo'>Fractional CFO</MenuItemWithLink>
                    </HoverMenu>
                    {/* <HoverMenu MenuButton={IndustriesLink}>
                        <MenuItem>Start Ups</MenuItem>
                        <MenuItem>Retail & Manufacturing</MenuItem>
                        <MenuItem>AProfessional Services</MenuItem>
                    </HoverMenu> */}
                    <li><Link to="/pricing" onClick={() => { window.scrollTo(0, 0) }}>Pricing</Link></li>
                    {/* <li>Strategies for Freelancers</li> */}
                </ul>
                <ul className="header_menu2">
                    {/* {user? (
                        <div>{user.first_name ? user.first_name : 'Guest'} ,<button className="header_logout" onClick={handleSignOut}> Logout</button></div>
                    ) : (
                        <li className='header_login' onClick={() => setIsModalOpen(true)}>Login</li> 
                    )} */}
                    {user && <HoverMenu MenuButton={ProfileLink}>
                        <MenuItemWithLink to="/profile">My Profile</MenuItemWithLink>
                        <MenuItemWithLink to="/documents">My Documents</MenuItemWithLink>
                        <MenuItemWithLink to="/bin">Bin</MenuItemWithLink>
                        <MenuItem><button className="header_logout" onClick={handleSignOut}> Logout</button></MenuItem>
                    </HoverMenu>}
                    {!user && <li className='header_login' onClick={() => setIsModalOpen(true)}>Login</li>}

                    <button className='header_button' onClick={() => {
                        setIsCartSubmit(false);
                        setModalVisibility(true)
                    }}>Talk to an expert</button>
                </ul>
            </div>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className='mobile_nav_drawer'
                style={{
                    width: '300px'
                }}
            >
                <div className="mobile_nav_header">
                    <img src={logo} alt='logo' className='drawer_logo' />
                    <button className='mobile-header-button' onClick={() => {
                        toggleDrawer()
                        setIsCartSubmit(false);
                        setModalVisibility(true)
                    }}>Talk to an expert</button>
                </div>
                <ul className="mobile_nav_links">
                    <li className="mobile-nav-link"><Link to='/' style={mobileLinkStyles} onClick={() => {
                        setActiveMobileTab("home");
                        window.scrollTo(0, 0);
                        toggleDrawer();
                    }}>
                        <span className={`link-container ${activeMobileTab === "home" ? "activeMobileTab" : ""}`}>
                            {activeMobileTab !== "home" && <img src={homeIcon} alt="" className="mobile-nav-link-img" />}
                            {activeMobileTab === "home" && <img src={homeActiveIcon} alt="" className="mobile-nav-link-img" />}
                            Home
                        </span>
                    </Link></li>
                    <li className="mobile-nav-link subsequent-link" onClick={() => onMobileSubmenuClick('services')}>
                        <span className={`submenu-container link-container ${activeMobileTab === "services" ? "activeMobileTab" : ""}`}>
                            {activeMobileTab !== "services" && <img src={customerIcon} alt="" className="mobile-nav-link-img" />}
                            {activeMobileTab === "services" && <img src={customerActiveIcon} alt="" className="mobile-nav-link-img" />}
                            Services
                            &nbsp;
                            <FaCaretRight className={`caretRightBasic ${mobileOpenedSubmenu === "services" ? "openMenu" : "closeMenu"}`} />
                        </span></li>
                    <div className={`expandable ${mobileOpenedSubmenu === 'services' ? 'expanded' : ''}`}>
                        <Link to='/services/personal-tax' className="submenu-links" onClick={() => {
                            setActiveMobileTab("services");
                            window.scrollTo(0, 0);
                            toggleDrawer();
                        }}>Personal Tax</Link>
                        <Link to='/services/business-tax' className="submenu-links" onClick={() => {
                            setActiveMobileTab("services");
                            window.scrollTo(0, 0);
                            toggleDrawer();
                        }}>Business Tax</Link>
                        <Link to='/services/ad-hoc-accounting' className="submenu-links" onClick={() => {
                            setActiveMobileTab("services");
                            window.scrollTo(0, 0);
                            toggleDrawer();
                        }}> Ad-hoc Accounting</Link>
                        <Link to='/services/bookkeeping' className="submenu-links" onClick={() => {
                            setActiveMobileTab("services");
                            window.scrollTo(0, 0);
                            toggleDrawer();
                        }}>Bookkeeping</Link>
                        <Link to='/services/fractional-cfo' className="submenu-links" onClick={() => {
                            setActiveMobileTab("services");
                            window.scrollTo(0, 0);
                            toggleDrawer();
                        }}>Fractional CFO</Link>
                    </div>
                    <li className="mobile-nav-link subsequent-link"><Link to='/pricing' style={mobileLinkStyles} onClick={() => {
                        setActiveMobileTab("pricing");
                        window.scrollTo(0, 0);
                        toggleDrawer();
                    }}>
                        <span className={`link-container ${activeMobileTab === "pricing" ? "activeMobileTab" : ""}`}>
                            {activeMobileTab !== "pricing" && <img src={moneyIcon} alt="" className="mobile-nav-link-img" />}
                            {activeMobileTab === "pricing" && <img src={moneyActiveIcon} alt="" className="mobile-nav-link-img" />}
                            Pricing
                        </span>
                    </Link></li>
                    {/* <li>Strategies for Freelancers</li> */}
                    <li>
                        {user && (
                            <div className="mobile-nav-links-logged-in-container" >
                                <div className="mobile-nav-links-heading" onClick={() => { toggleMobileAuthLinks() }}>
                                    <img className="profile-icon-mobile" src={userIcon} alt="" />
                                    {user.first_name ? user.first_name : "Guest"}
                                    &nbsp;
                                    <FaCaretRight className={`caretRightBasic ${showMobileAuthLinks ? "openMenu" : "closeMenu"}`} />
                                </div>
                                <div className={`expandable ${showMobileAuthLinks ? "expanded" : ''}`} >
                                    <ul className="mobile-nav-links-logged-in-list">
                                        <li><Link to='/profile' style={mobileLinkStyles} onClick={(e) => {
                                            window.scrollTo(0, 0);
                                            toggleDrawer();
                                            e.stopPropagation();
                                        }}>My Profile </Link></li>
                                        <li><Link to='/documents' style={mobileLinkStyles} onClick={(e) => {
                                            window.scrollTo(0, 0);
                                            toggleDrawer();
                                            e.stopPropagation();
                                        }}>My Documents </Link></li>
                                        <li><Link to='/bin' style={mobileLinkStyles} onClick={(e) => {
                                            window.scrollTo(0, 0);
                                            toggleDrawer();
                                            e.stopPropagation();
                                        }}>Bin </Link></li>
                                        <li className="header_logout" onClick={handleSignOut} >Logout</li>
                                    </ul>
                                </div>

                            </div>
                        )}
                        {!user && <span className='header_login' onClick={() => setIsModalOpen(true)}>
                            <FaRegUser className="unlogged-icon"/>
                            Login</span>}
                    </li>
                </ul>
            </Drawer>
            <LoginPopup isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleSignOut={handleSignOut} />
        </>
    )
}

export default Header;
