import { useRef, useState } from 'react';
import { ControlledMenu, MenuItem, useHover, useMenuState } from '@szhsin/react-menu';

function HoverMenu({ MenuButton, children }){
    const ref = useRef(null);
    const [menuState, toggle] = useMenuState({ transition: true });
    const { anchorProps, hoverProps } = useHover(menuState.state, toggle);
    
    return (
        <>
            <MenuButton ref={ref} {...anchorProps}/>
            <ControlledMenu
                {...hoverProps}
                {...menuState}
                anchorRef={ref}
                onClose={() => toggle(false)}
            >
                {children}
            </ControlledMenu>
        </>
    );

}
export default HoverMenu;