import axios from "axios";

const UserApis = () => {
    const registerUserByFirebase = async(email, authServiceId) => {
        try {
            let data = JSON.stringify({
                query: `mutation RegisterUser($auth_service_id: String = "", $address: String = "", $city: String = "", $country: String = "", $email: String = "", $first_name: String = "", $is_deleted: Boolean = false, $last_name: String = "", $password: String = "", $phone_number: String = "", $state: String = "", $zip_code: String = "") {
                insert_users_one(object: {address: $address, auth_service_id: $auth_service_id, city: $city, country: $country, email: $email, first_name: $first_name, is_deleted: $is_deleted, last_name: $last_name, password: $password, phone_number: $phone_number, state: $state, zip_code: $zip_code}) {
                  Id
                  address
                  auth_service_id
                  city
                  country
                  email
                  first_name
                  is_deleted
                  last_name
                  password
                  phone_number
                  state
                  updated_at
                  zip_code
                }
              }`,
                variables: {"auth_service_id": authServiceId,"email": email}
              });
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_HASURA_BASE_URL}`,
                headers: { 
                  'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`, 
                  'Content-Type': 'application/json'
                },
                data : data
              };
    
              let registerUserResponse = await axios(config)
              console.log("registerUserResponse", registerUserResponse);
              if(registerUserResponse.data.errors) {
                const errorMessage = registerUserResponse.data.errors[0].message;
                if(errorMessage.includes("Uniqueness violation.") === true) {
                  throw new Error("USER_EXISTS");
                } 
              }
              return registerUserResponse.data.data.insert_users_one;
        } catch (error) {
            if(error.message === "USER_EXISTS") {
              return null;
            }
            console.error(`register user by firebase failed due to ${error.message? error.message: error}`);
        }
          
    }

    const updateUserById = async(id, user) => {
        try {
            let setObject = "";
            for(let key in user) {
              if(key !== "Id") {
                setObject += `${key}: $${key}, `
              }
            }
            let variables = {...user, "Id": id};
            setObject = `{ ${setObject.slice(0, -2)} }`;
            let data = JSON.stringify({
                query: `mutation UpdateUser($Id: uuid = "", $address: String = "", $auth_service_id: String = "", $city: String = "", $country: String = "", $email: String = "", $first_name: String = "", $is_deleted: Boolean = false, $last_name: String = "", $password: String = "", $phone_number: String = "", $state: String = "", $zip_code: String = "") {
                update_users_by_pk(pk_columns: {Id: $Id}, _set: ${setObject}) {
                  Id
                  address
                  auth_service_id
                  city
                  country
                  created_at
                  email
                  first_name
                  is_deleted
                  last_name
                  password
                  phone_number
                  state
                  updated_at
                  zip_code
                }
              }`,
                variables: variables
              });
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_HASURA_BASE_URL}`,
                headers: { 
                  'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`, 
                  'Content-Type': 'application/json'
                },
                data : data
              };
              let updatedUserResponse = (await axios(config)).data.data.update_users_by_pk;
              return updatedUserResponse;
        } catch (error) {
            console.error(`update user by id failed due to ${error.message? error.message: error}`);
        }
    }

    const getUserById = async(id, cache = true) => {
        try {
            let data = JSON.stringify({
                query: `query GetUserById($Id: uuid = "") ${cache === true? "@cached": "@cached(refresh: true)"} {
                users_by_pk(Id: $Id) {
                  Id
                  address
                  auth_service_id
                  city
                  country
                  created_at
                  email
                  first_name
                  is_deleted
                  last_name
                  password
                  phone_number
                  state
                  updated_at
                  zip_code
                }
              }`,
                variables: {"Id": id}
              });
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_HASURA_BASE_URL}`,
                headers: { 
                  'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`, 
                  'Content-Type': 'application/json'
                },
                data : data
              };
    
              let getUserResponse = (await axios(config)).data.data.users_by_pk;
              return getUserResponse;
        } catch (error) {
            console.error(`get user by id failed due to ${error.message? error.message: error}`);
        }
    }

    const getUserByAuthServiceId = async(authServiceId) => {
        try {
            let data = JSON.stringify({
                query: `query GetUserByAuthServiceId($auth_service_id: String = "") @cached {
                users(where: {auth_service_id: {_eq: $auth_service_id}}, limit: 1) {
                  Id
                  address
                  auth_service_id
                  city
                  country
                  created_at
                  email
                  first_name
                  is_deleted
                  last_name
                  password
                  phone_number
                  state
                  updated_at
                  zip_code
                }
              }`,
                variables: {"auth_service_id":authServiceId}
              });
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_HASURA_BASE_URL}`,
                headers: { 
                  'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`, 
                  'Content-Type': 'application/json'
                },
                data : data
              };
    
              let getUserByAuthServiceIdResponse = (await axios(config)).data.data.users;
              if(getUserByAuthServiceIdResponse.length === 0) {
                return null;
              } 
              return getUserByAuthServiceIdResponse[0];
        } catch (error) {
            console.error(`get user by auth service id failed due to ${error.message? error.message: error}`);
        }
    }

    const getUserByEmail = async(email,cache = true) => {
      try {
        let data = JSON.stringify({
          query: `query GetUserByEmail($email: String = "") ${cache === true? "@cached": "@cached(refresh: true)"} {
            users(where: {email: {_eq: $email}}, limit: 1) {
              Id
              address
              auth_service_id
              city
              country
              created_at
              email
              first_name
              is_deleted
              last_name
              password
              phone_number
              state
              updated_at
              zip_code
            }
          }`,
          variables: {"email":email}
        });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_HASURA_BASE_URL}`,
          headers: { 
            'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`, 
            'Content-Type': 'application/json'
          },
          data : data
        };

        let getUserByEmailResponse = (await axios(config)).data.data.users;
        if(getUserByEmailResponse.length === 0) {
          return null;
        } 
        return getUserByEmailResponse[0];
      } catch (error) {
        console.error(`get user by email failed due to ${error.message? error.message: error}`);
      }
    }

    const generateDocAccessToken = async (userId) => {
      try {
        let data = JSON.stringify({
          "userId": userId,
          "role": "user"
        });
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/auth/createAuthToken`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        const tokenResponse = await axios(config);
        return tokenResponse.data.token;
      } catch (error) {
        if(error.response) {
          throw new Error(`Failed to create access token`)
        }
        throw error;
      }
      
    }

    return {
      registerUserByFirebase,
      updateUserById,
      getUserById,
      getUserByAuthServiceId,
      getUserByEmail,
      generateDocAccessToken
    }
}


const userApis = UserApis();
export default userApis;