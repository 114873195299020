import { Routes, Route, Navigate } from "react-router-dom"
import HomePage from "./pages/Homepage";
import Layout from "./pages/Layout";
import NotFound from "./pages/404";
import PersonalTax from "./pages/Personal_tax";
import Pricing from "./pages/Pricing";
import OtherServices from "./pages/OtherServices";
import BookKeeping from "./pages/BookKeeping";
import FractionalCFOTest from './pages/CFO'
import CFOData from "./Constant/BookKeeping&CFO";
import ProfilePage from "./pages/Profile";
import BusinessTax from "./pages/BusinessTax";
import MyDocuments from "./pages/ProfilePages/MyDocuments";
import { UserAuth } from "./context/AuthProvider";

function Router({ children }) {
    const {user} = UserAuth();
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route index element={<HomePage />} />
                <Route path="/services/personal-tax" element={<PersonalTax />} />
                <Route path="/services/business-tax" element={<BusinessTax />} />
                <Route path="/services/ad-hoc-accounting" element={<OtherServices />} />
                <Route path="/services/bookkeeping" element={<BookKeeping />} />
                <Route
                    path="/services/bookkeeping-test"
                    element={<FractionalCFOTest data={CFOData.BookKeeping} />}
                />
                <Route
                    path="/services/fractional-cfo"
                    element={<FractionalCFOTest data={CFOData.CFO} />}
                />
                {user !== null && <Route path="/profile" element={<ProfilePage />} />}
                {user !== null && <Route path="/documents" element={<MyDocuments page="main" />} />}
                {user !== null && <Route path="/documents/:planId" element={<MyDocuments page="plan" />} />}
                {user !== null && <Route path="/documents/:planId/:parentDir" element={<MyDocuments page="file" />} />}
                {user !== null && <Route path="/bin" element={<MyDocuments page="bin" />} />}

                <Route path="/pricing" element={<Pricing />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}
export default Router;
