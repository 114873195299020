import React, { useState } from 'react';
import MyPlans from './ProfilePages/MyPlans';
import MyDocuments from './ProfilePages/MyDocuments';
import Profile from './ProfilePages/Profile';
import Bin from './ProfilePages/Bin';
import Settings from './ProfilePages/Settings';
import '../Css/Profile.css'
import { Navigate, Outlet, Route, Router, Routes, useNavigate } from 'react-router-dom';


const ProfilePage = () => {
  const navigate = useNavigate();

  return (
      <div className="right-content">
        <Profile />
      </div>
  );
};

export default ProfilePage;
