import { useNavigate } from 'react-router-dom';
import "../../Css/SideMenu.css";
import '../../Css/Profile.css';
import { UserAuth } from '../../context/AuthProvider';
const SideMenu = () => {
    const {user} = UserAuth();
    const navigate = useNavigate();
    return (
        <>
        {user !== null && <div className="left-options">
            <div className="sideMenuItem" onClick={() => navigate('/profile')}>Profile</div>
            <div className="sideMenuItem" onClick={() => navigate('/documents')}>My Documents</div>
            <div className="sideMenuItem" onClick={() => navigate('/bin')}>Bin</div>
        </div>}
        </>
    );
}

export default SideMenu;