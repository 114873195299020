import axios from "axios";

const DocumentApis = () => {
    const uploadDocument = async (token, fileTitle = "", file, parentDir, planId) => {
        try {
            let data = new FormData();
            data.append("fileTitle", fileTitle);
            data.append('file', file);
            data.append('parentDir', parentDir);
            data.append('planId', planId);

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/file/uploadDocument`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };
            const uploadResponse = await axios(config);
            return uploadResponse.data;
        } catch (error) {
            if(error.response) {
                if(error.response.status === 401) {
                    throw {
                        status: error.response.status,
                        message: "UNAUTHORIZED"
                    };
                }
            }
            throw error;
        }
    }

    const getUserDocumentsByPlanIdAndParentDir = async (token, parentDir, planId, cached) => {
        try {
            const parentDirEncoded = encodeURIComponent(parentDir);
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/file/getDocuments/${planId}/${parentDirEncoded}?cached=${cached}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            const documentResponse = await axios(config);
            return documentResponse.data;
        } catch (error) {
            if(error.response) {
                if(error.response.status === 401) {
                    throw {
                        status: error.response.status,
                        message: "UNAUTHORIZED"
                    };
                }
            }
            throw error;
        }
    }

    const getDeletedDocuments = async (token) => {
        try {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/file/getDeletedDocuments`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            const documentResponse = await axios(config);
            return documentResponse.data;
        } catch (error) {
            if(error.response) {
                if(error.response.status === 401) {
                    throw {
                        status: error.response.status,
                        message: "UNAUTHORIZED"
                    };
                }
            }
            throw error
        }
    }

    const setDocumentDeleteFlag = async (token, documentId, deleteFlag) => {
        try {
            let data = JSON.stringify({
                "documentIds": [
                  documentId
                ],
                "deleteFlag": deleteFlag
              });
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/file/setDocumentDeleteFlag`,
                headers: { 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${token}`
                },
                data : data
              };
            
            const successResponse = await axios(config);
            return successResponse.data.success;
        } catch (error) {
            if(error.response) {
                if(error.response.status === 401) {
                    throw {
                        status: error.response.status,
                        message: "UNAUTHORIZED"
                    };
                }
            }
            throw error;
        }
    }

    const getDocumentFile = async (token, documentKey, responseType = "") => {
        try {
            const documentKeyQuery = encodeURIComponent(documentKey);
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/file/getDocument?key=${documentKeyQuery}`,
                responseType: responseType,
                headers: { 
                  'Authorization': `Bearer ${token}`
                }
              };
    
            const response = await axios(config);
            return response;
        } catch (error) {
            if(error.response) {
                if(error.response.status === 401) {
                    throw {
                        status: error.response.status,
                        message: "UNAUTHORIZED"
                    };
                }
            }
            throw error;
        }
        
    }

    return {
        uploadDocument,
        getUserDocumentsByPlanIdAndParentDir,
        getDeletedDocuments,
        setDocumentDeleteFlag,
        getDocumentFile
    }
}

const documentApis = DocumentApis();
export default documentApis;