import React, { useContext, useEffect, useState } from 'react'
import { UserAuth } from '../../context/AuthProvider';
import { FileSystemContext } from '../../context/FileSystemProvider';
import DocumentComponent from '../../Components/DocumentComponent';
import '../../Css/MyDocuments.css'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import apiRegenerateToken from '../../Utils/ApiRegenerateToken';
import documentApis from '../../Apis/DocumentApis';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Modal from "react-modal";
import UploadDocumentModal from '../../Components/UploadDocumentModal';
import emptyFile from '../../Assets/emptyPlaceholder.png';
import { PlansContext } from '../../context/PlansProvider';
import { FaChevronRight, FaArrowLeft, FaPlus } from 'react-icons/fa';

const MyDocuments = ({ page }) => {
  const [userData, setUserData] = useState({});
  const [userPlansData, setUserPlansData] = useState([]);
  const [userDocsData, setUserDocsData] = useState([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState('');
  const { userOrderPlansJoin, userHasActivePlans, isLoading, setIsLoading } = useContext(FileSystemContext);
  const { allPlans } = useContext(PlansContext);
  const navigate = useNavigate();
  const location = useLocation();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "91%",
      maxWidth: "35rem",
      padding: 0
    },
    overlay: {
      background: "rgba(166,166,166,0.65)",
      zIndex: 999
    }
  };

  const customLinkStyles = {
    textDecoration: "none",
    color: "var(--text-color)"
  }

  /**
   * page is ENUM of either main, plan, file
   */
  const { user, setUser, logOut } = UserAuth();
  const params = useParams();

  const checkUserDataHasSize = () => {
    if (Array.isArray(userData)) {
      return userData.length > 0 ? true : false;
    } else if (typeof userData === 'object' && userData !== null) {
      return Object.values(userData).length > 0 ? true : false;
    } else {
      return false;
    }

  }

  const checkUserDataHasSizeByPage = (page) => {
    switch(page) {
      case 'main':
        return Object.values(userData || {}).length > 0 ? true : false;
      case 'plan':
        return (userPlansData || []).length > 0 ? true : false;
      case 'file':
        return (userDocsData || []).length > 0 ? true : false;
      case 'bin':
        return (userDocsData || []).length > 0 ? true : false;
      default:
        console.error("Page is not valid")
    }
  }

  const fetchPlanNameFromId = (planId) => {
    const currentPlan = allPlans.filter((plan) => {
      return plan.id === parseInt(planId);
    });
    if(currentPlan.length > 0) {
      return currentPlan[0].name;
    }
    return "";
  }

  const fetchUserDocuments = async (planId, parentDir, cached) => {
    try {
      setIsLoading(true);
      let response = null;
      let userId;
      let token;
      console.log("fetchUserDocuments", user);
      if (user.Id && user.token) {
        userId = user.Id;
        token = user.token;
      } else {
        throw new Error("User not found");
      }
      if (!token) {
        token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
      }

      try {
        response = await documentApis.getUserDocumentsByPlanIdAndParentDir(token, parentDir, planId, cached);
        console.log(response);
      } catch (error) {
        if (error.status === 401) {
          token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
          if (token)
            response = await documentApis.getUserDocumentsByPlanIdAndParentDir(token, parentDir, planId, cached);
        } else {
          throw error;
        }
      }
      return response.documents;
    } catch (error) {
      console.error(`ERROR: Failed to fetch user documents due to ${error.message ? error.message : error}`);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchDeletedUserDocuments = async () => {
    try {
      setIsLoading(true);
      let response = null;
      let userId;
      let token;
      console.log("fetchDeletedUserDocuments", user);
      if (user.Id && user.token) {
        userId = user.Id;
        token = user.token;
      } else {
        throw new Error("User not found");
      }
      if (!token) {
        token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
      }

      try {
        response = await documentApis.getDeletedDocuments(token);
      } catch (error) {
        if (error.status === 401) {
          token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
          if (token)
            response = await documentApis.getDeletedDocuments(token);
        } else {
          throw error;
        }
      }

      return response.documents;

    } catch (error) {
      console.error(`ERROR: Failed to fetch user documents due to ${error.message ? error.message : error}`);
    } finally {
      setIsLoading(false);
    }
  }
  checkUserDataHasSizeByPage(page)
  useEffect(() => {
    /**Switch condition on props page*/
    console.log("userOrderPlansJoin", userOrderPlansJoin);
    try {
      switch (page) {
        case 'main': {
          setUserActivePlans(userOrderPlansJoin);
          break;
        }
        case 'plan': {
          /**Get planId from path params */
          checkUserDataHasSizeByPage(page)
          const planId = parseInt(params.planId);
          setPlansFolders(userOrderPlansJoin, planId);
          break;
        }

        case 'file': {
          /**Get planId and parentDir from path params */
          const planId = parseInt(params.planId);
          const parentDir = params.parentDir;
          setUserPlanDocs(planId, parentDir);
          break;

        }

        case 'bin': {
          setDeletedDocs();
          break;
        }

        default:
          throw new Error("Case not found");
      }
    } catch (error) {
      console.error(`ERROR: failed to load render data for myDocuments due to ${error.message ? error.message : error}`);
    }
  }, [page, userOrderPlansJoin]);

  const renderMainPage = () => {
    console.log("Hello from main page");
    console.log("UserData: ", userData)
    return (
      <>
        {checkUserDataHasSizeByPage(page) && Object.values(userData).map((item, index) => {
          return (
            <DocumentComponent key={`Folder_${index}`} type={"folder"} obj={{
              name: item.planName,
              createdAt: item.createdAt,
              id: item.planId,
              to: "plan"
            }} />
          );
        })}
      </>
    );
  }

  const renderPlanFolders = () => {
    console.log("userData", userData);
    console.log("page", page);
    return (
      <>
        {checkUserDataHasSizeByPage(page) && userPlansData.map((item, index) => {
          return (
            <DocumentComponent key={`Folder_${index}`} type={"folder"} obj={{
              name: item.parentDir,
              createdAt: item.createdAt,
              id: item.planId,
              to: "file"
            }} />
          );
        })}
      </>
    );
  }

  const renderUserDocs = () => {
    return (
      <>
        {checkUserDataHasSizeByPage(page) && userDocsData.map((item, index) => {
          return (
            <DocumentComponent key={`File_${index}`} type={"file"} userData={userDocsData} setUserData={setUserDocsData} obj={{
              docId: item.id,
              docTitle: item.doc_title,
              createdAt: item.created_at,
              docSize: item.doc_size,
              docType: item.doc_type,
              docKey: item.doc_key,
            }} />
          );
        })}
      </>
    );
  }

  const renderUserDeletedDocs = () => {
    return (
      <>
        {checkUserDataHasSizeByPage(page) && userDocsData.map((item, index) => {
          return (
            <DocumentComponent key={`Bin_File_${index}`} type={"bin"} userData={userDocsData} setUserData={setUserDocsData} obj={{
              docId: item.id,
              docTitle: item.doc_title,
              createdAt: item.created_at,
              docSize: item.doc_size,
              docType: item.doc_type,
              docKey: item.doc_key,
            }} />
          );
        })}
      </>
    );
  }

  const renderNavigationHeaders = () => {
    return (
      <div className="navigation-header-container">
        <div className="navigation-header"><Link style = {customLinkStyles} to = {isLoading? "#":"/documents"} >My Documents</Link></div>
        {params.planId && <>
          <span className='navigation-break'><FaChevronRight/></span>
          <Link style = {customLinkStyles} to = {isLoading? "#":`/documents/${params.planId}`}><div className="navigation-header">{
            params.parentDir? fetchPlanNameFromId(params.planId).substring(0, 10) + "...": fetchPlanNameFromId(params.planId).substring(0, 20) + "..."
          }</div></Link>
        </>}
        {params.parentDir && <>
          <span className='navigation-break'><FaChevronRight/></span>
          <div className="navigation-header">
          <Link style = {customLinkStyles} to = {isLoading? "#":`/documents/${params.planId}/${params.parentDir}`}>{params.parentDir}</Link>
            </div>
        </>}
      </div>
    )
  }

  const setUserActivePlans = (orderPlanJoinData) => {
    try {
      console.log("--->", userData);
      const plansData = {};
      for (let item of orderPlanJoinData) {
        if (!(item.planId in plansData))
          plansData[item.planId] = {
            planId: item.planId,
            planName: item.planName,
            category: item.planCategory,
            createdAt: item.createdAt
          }
      }
      setUserData(plansData);
      console.log("--->", userData);
    } catch (error) {
      console.error(`ERROR: Couldn't set user plans due to error ${error.message ? error.message : error}`);
    }
  }

  const setPlansFolders = (orderPlanJoinData, planId) => {
    try {
      const planFolders = orderPlanJoinData.filter((item) => {
        if (item.planId === planId) {
          setCurrentSelectedPlan(item.planName);
          return {
            planId: item.planId,
            parentDir: item.parentDir,
            createdAt: item.createdAt
          }
        }
      });

      setUserPlansData(planFolders);

    } catch (error) {
      console.error(`ERROR: Couldn't set user plans due to error ${error.message ? error.message : error}`);
    }
  }

  const setUserPlanDocs = async (planId, parentDir, cached = true) => {
    try {
      /**Call api to get all docuements inside plan's parentDir */
      setUserDocsData([]);
      let documents = await fetchUserDocuments(planId, parentDir, cached);
      setUserDocsData(documents);
    } catch (error) {
      console.error(`ERROR: Couldn't set user documents due to error ${error.message ? error.message : error}`);
    }
  }

  const setDeletedDocs = async () => {
    try {
      setUserDocsData([]);
      let deletedDocs = await fetchDeletedUserDocuments();
      console.log("setDeletedDocs", deletedDocs);
      setUserDocsData(deletedDocs);
    } catch (error) {
      console.error(`ERROR: Couldn't set user deleted documents due to error ${error.message ? error.message : error}`)
    }
  }

  const getPageHeading = (page) => {
    switch (page) {
      case "main":
        return "My Documents";
      case "plan":
        return (fetchPlanNameFromId(params.planId).substring(0, 20) + "...");
      case "file":
        return params.parentDir;
      case "bin":
        return "Bin"
      default:
        return ""
    }
  }

  return (
    <>
      <div className="document-container">
        {/* Render navigation links */}
        {page !== "bin" && renderNavigationHeaders()}
        {<div className='page-heading-container'>
          {page !== "bin" && page !== "main" && <div className="page-back-button">
            <FaArrowLeft onClick={() => {
              navigate(-1);
            }}/>
          </div>}
          <span className="page-heading">{getPageHeading(page)}</span>
        </div>}

        {/* Render an upload button */}
        {page === "file" && userHasActivePlans && <div className={`upload-new-container ${userData.length < 10 ? "upload-new-pos": ""}`}>
          <button className="upload-new" onClick={() => setIsUploadModalOpen(true)}>+&nbsp;New</button>
        </div>}
        { checkUserDataHasSizeByPage(page) && <DocumentComponent type="heading" />}

        {isLoading &&
          <div className='loader-container-skeleton'><Skeleton count={10} /></div>
        }

        {!isLoading && (checkUserDataHasSizeByPage(page) === false) &&
          <div className="placeholder-img-container">
            <img src={emptyFile} alt="" className="placeholder-img" />
            {page !== 'file' && page !== 'bin' && <div>No Plans are active yet.</div>}
            {page === 'file' && <div>To Add files click on the {window.innerWidth < 480 ? "+": "New"} button</div>}
            {page === 'bin' && <div>Bin is empty!</div>}
          </div>
        }

        {page === "main" && renderMainPage()}

        {page === "plan" && renderPlanFolders()}

        {page === "file" && renderUserDocs()}

        {page === "bin" && renderUserDeletedDocs()}
      </div>
      <Modal isOpen={isUploadModalOpen} onRequestClose={() => {
        if (true) {
          setIsUploadModalOpen(false);
        }
      }}
        style={customStyles}>
        <UploadDocumentModal setIsUploadModalOpen={setIsUploadModalOpen} setUserPlanDocs={setUserPlanDocs} />

      </Modal>
    </>
  )
}

export default MyDocuments
