import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import Modal from 'react-modal';
import loginpopup from '../Assets/login_popup.jpg';
import logo from '../Assets/Spyder Logo.png';
import '../Css/LoginPopup.css';
import infoIcon from '../Assets/info-icon.png';
import closeIcon from '../Assets/multiply.png';

export const LoginPopup = ({ isOpen, setIsModalOpen, handleSignOut }) => {
  const [user] = useAuthState(auth);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const [infoMsg, setInfoMsg] = useState('');

  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState('');

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "91%",
      maxWidth: "35rem",
      padding: 0
    },
    overlay: {
      background: "rgba(166,166,166,0.65)",
      zIndex: 999
    }
  };

  useEffect(() => {
    if (user) {
    }
    else {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        console.log("signInEmailLinkHref: ", window.location.href)
        let email = localStorage.getItem('email');
        if (!email) {
          email = window.prompt('Please provide your email');
          localStorage.setItem('email', email);
        }
        setInitialLoading(true);
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            localStorage.removeItem('email');
            setInitialLoading(false);
            setInitialError('');
            navigate('/');
          }).catch((err) => {
            setInitialLoading(false);
            setInitialError("Something went wrong. Please retry after sometime");
            navigate('/');
          })
      }
      else {

      }
    }
  }, [user, search, navigate]);

  const handleLogin = () => {
    // e.preventDefault();
    console.log("REACT_APP_SELF_URL: ", process.env.REACT_APP_SELF_URL)
    setLoginLoading(true);
    sendSignInLinkToEmail(auth, email, {
      url: `${process.env.REACT_APP_SELF_URL}`,
      handleCodeInApp: true,
    }).then(() => {
      localStorage.setItem('email', email);
      setLoginLoading(false);
      setLoginError('');
      setInfoMsg('We have sent you an email with a sign-in link');
    }).catch(err => {
      setLoginLoading(false);
      setLoginError("Invalid Input");
    })
  }


  return (
    // <Modal
    //   className="login-popup"
    //   isOpen={isOpen}
    //   onRequestClose={() => setIsModalOpen(false)}
    // >
    // <div className="login-popup-active">
    //   <div className="login-popup-left">
    //     <img src={loginpopup} className="login-popup-left-bg" />
    //     <div className="login-popup-left-section">
    //       <h3 className="login-popup-left-text">Your Trusted Companion for Tax Form Assistance </h3>
    //       <img src={logo} className="login-popup-left-logo" />
    //     </div>
    //   </div>
    //   <div className="login-popup-right-section">
    //     <h3 className="login-popup-right-text1">Welcome to Spyder</h3>
    //     <h4 className="login-popup-right-text2">Log in to your account</h4>
    //     <div className='box'>
    //       {initialLoading ? (
    //         <div>Loading...</div>
    //       ) : (
    //         <>
    //           {initialError !== '' ? (
    //             <div className='error-msg'>{initialError}</div>
    //           ) : (
    //             <>
    //               {user ? (

    //                 <div>{user.first_name ? user.first_name : 'Guest'} ,<button className="header_logout" onClick={handleSignOut}> Logout</button></div>
    //               ) : (
    //                 <form className='form-group custom-form' onSubmit={handleLogin}>
    //                   <input type={'email'} required placeholder='Enter your email address'
    //                     className='form-control login-popup-right-input'
    //                     value={email || ''} onChange={(e) => setEmail(e.target.value)} />
    //                   <button type='submit' className='btn btn-success btn-md login-popup-right-button'>
    //                     {loginLoading ? (
    //                       <span>Logging you in</span>
    //                     ) : (
    //                       <span>Login with Email</span>
    //                     )}
    //                   </button>
    //                   {loginError !== '' && (
    //                     <div className='error-msg'>{loginError}</div>
    //                   )}
    //                   {infoMsg !== '' && (
    //                     <div className='info-msg'>{infoMsg}</div>
    //                   )}
    //                 </form>
    //               )}
    //             </>
    //           )}
    //         </>
    //       )}
    //     </div>
    //     {/* <h5 className="login-popup-right-text3">Enter your email to get started</h5>
    //               <input placeholder="Enter Your Email address" className="login-popup-right-input"/> */}
    //     {/* <button className="login-popup-right-button">Login with Email</button> */}
    //     <p className="login-popup-right-text4">Get instant access with a secure code emailed to you.</p>
    //     <p className="login-popup-right-text5">Not registered? Create an account</p>
    //   </div>
    // </div>
    // </Modal>
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setEmailError(false);
        setIsModalOpen(false)
      }}
      style={customStyles}>
      <div className="login-modal-container">
        <div className='login-close-icon-container'>
          <img src={closeIcon} alt="close icon" className="modal-form-close-button" onClick={() => {
            setEmailError(false);
            setIsModalOpen(false)
            }} />
        </div>
        <div className="login-left-section">
          <div className="mobile-logo-container">
            <img src={logo} alt="" className="mobile-logo" />
          </div>
          <div className="left-section-heading">
            Your Trusted Companion for Tax Form Assistance
          </div>
          <div className="desktop-logo-container">
            <img src={logo} alt="" className="desktop-logo" />
          </div>
        </div>
        <div className="login-right-section">
          <div className="login-right-section-card">
            <div className="login-right-section-title centered-column">
              <h2 className="main-title">Welcome to Spyder</h2>
              <div className="sub-title">{!user ? "Login to your account" : "Logged In"}</div>
              {!user && <span className="title-instruction">Enter your email to get started</span>}
            </div>
            {!user && <div className="login-right-section-card-body centered-column">
              <div className="login-form-group">
                <input type="text" className="login-form-control" placeholder="Enter your email address*" onChange={(event) => {
                  setEmail(event.target.value);
                  setEmailError(true);
                  setInfoMsg('');
                  setLoginError('');
                  setInitialError('');
                }}/>
                {emailError && !email && <span className="login-sub-text error-style">Please fill out the email field</span>}
                {infoMsg && <span className="login-sub-text">{infoMsg}</span>}
                {loginError && <span className="login-sub-text error-style">{loginError}</span>}
              </div>
              <div className="login-form-group">
                <div className={`login-form-submit ${!email? "disabled": ""}`} onClick = {() => {
                  if(!email) return
                  handleLogin();
                }}>
                  {loginLoading ? (
                    "Logging you in"
                  ) : (
                    "Login with Email"
                  )}

                </div>

              </div>

              <div className="login-right-section-footer centered-column">
                <span className="login-right-info">
                  <img src={infoIcon} alt="" className="info-img" />
                  Get instant access with a secure link emailed to you.
                </span>
              </div>
            </div>}
            <div className="login-right-section-card-body centered-column">
              {initialLoading && <div className="logged-in-box">Logging in...</div>}
              {initialError && <div className="logged-in-error-box">{initialError}</div>}
              {user && <div className="logged-in-box">{user.first_name ? user.first_name : 'Guest'} ,<button className="header_logout" onClick={handleSignOut}> Logout</button></div>}
            </div>
          </div>

        </div>
      </div>
    </Modal>
  );
};
