import { createContext, useState } from "react";

export const PageContext = createContext('');

const PageProvider = ({children}) => {
    const [activeMobileTab, setActiveMobileTab] = useState('');
    return ( 
        <PageContext.Provider value={{activeMobileTab, setActiveMobileTab}}>
            {children}
        </PageContext.Provider>
     );
}

export default PageProvider;