import moment from "moment";
import { createContext, useEffect, useState } from "react";
import { UserAuth } from "./AuthProvider";
import userApis from "../Apis/UserApi";
import plansApi from "../Apis/PlansApis";
import apiRegenerateToken from "../Utils/ApiRegenerateToken";

export const FileSystemContext = createContext([]);

const FileSystemProvider = ({children}) => {
    const [userOrderPlansJoin, setUserOrderPlansJoin] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {user, setUser, logOut} = UserAuth();
    const [userHasActivePlans, setUserHasActivePlans] = useState([]);

    useEffect(() => {
        loadUserPlans();
    }, [user]);

    const loadUserPlans = async () => {
        try {
            // const userDataResponse = {
            //     "success": true,
            //     "documents": {
            //         "orders": [
            //             {
            //                 "created_at": "2024-04-26T09:34:26.855827+00:00",
            //                 "id": 1,
            //                 "isCancelled": false,
            //                 "payment_source": null,
            //                 "payment_status": "pending",
            //                 "total_price": 100,
            //                 "updated_at": "2024-04-26T09:34:26.855827+00:00",
            //                 "user_id": "964a6f11-ce97-4fea-98f6-e7d9155dec75",
            //                 "order_plans": [
            //                     {
            //                         "created_at": "2024-04-26T09:42:17.993887+00:00",
            //                         "id": 10,
            //                         "order_id": 1,
            //                         "plan_id": 17,
            //                         "plan_price": 50,
            //                         "plan": {
            //                             "category": "Federal Fillings",
            //                             "create_at": "2024-04-04T08:44:43.88499+00:00",
            //                             "description": "Required if US corporation has a foreign subsidiary at any time during tax year",
            //                             "features": [],
            //                             "id": 17,
            //                             "is_deleted": false,
            //                             "max_val": 0,
            //                             "min_val": 0,
            //                             "name": "Form 5471 (if any foreign subsidiary)",
            //                             "plan_type": "fixed",
            //                             "prices": [
            //                                 {
            //                                     "price": "$350",
            //                                     "duration": "/ yr"
            //                                 }
            //                             ],
            //                             "sort_id": 5,
            //                             "type": "Income Tax",
            //                             "updated_at": "2024-04-19T14:08:17.407121+00:00"
            //                         },
            //                         "plan_value": 60,
            //                         "updated_at": "2024-04-26T09:42:17.993887+00:00"
            //                     },
            //                     {
            //                         "created_at": "2024-04-26T11:39:45.377486+00:00",
            //                         "id": 11,
            //                         "order_id": 1,
            //                         "plan_id": 13,
            //                         "plan_price": 50,
            //                         "plan": {
            //                             "category": "",
            //                             "create_at": "2024-04-04T08:34:18.503611+00:00",
            //                             "description": null,
            //                             "features": [
            //                                 "Weekly Bookkeeping",
            //                                 "Dedicated CPA",
            //                                 "Free State/Extension Filings"
            //                             ],
            //                             "id": 13,
            //                             "is_deleted": false,
            //                             "max_val": 750000,
            //                             "min_val": 1,
            //                             "name": "PRO Bookkeeping",
            //                             "plan_type": "threshold_step",
            //                             "prices": [
            //                                 {
            //                  throw new Error("User not found");                   "price": "$100",
            //                                     "threshold": 1500
            //                                 },
            //                                 {
            //                                     "price": "$150",
            //                                     "threshold": 8000
            //                                 },
            //                                 {
            //                                     "price": "$300",
            //                                     "threshold": 75000
            //                                 },
            //                                 {
            //                                     "price": "$400",
            //                                     "threshold": 100000
            //                                 },
            //                                 {
            //                                     "price": "$500",
            //                                     "threshold": 150000
            //                                 },
            //                                 {
            //                                     "price": "$650",
            //                                     "threshold": 200000
            //                                 },
            //                                 {
            //                                     "price": "$750",
            //                                     "threshold": 250000
            //                                 },
            //                                 {
            //                                     "price": "$850",
            //                                     "threshold": 350000
            //                                 },
            //                                 {
            //                                     "price": "$1050",
            //                                     "threshold": 500000
            //                  throw new Error("User not found");               },
            //      throw new Error("User not found");                           {
            //                                     "price": "$1200",
            //                                     "threshold": 750000
            //                                 }
            //                             ],
            //                             "sort_id": 2,
            //                             "type": "Bookkeeping",
            //                             "updated_at": "2024-04-19T14:02:50.735149+00:00"
            //                         },
            //                         "plan_value": 60,
            //                         "updated_at": "2024-04-26T11:39:45.377486+00:00"
            //                     }
            //                 ]
            //             },
            //             {
            //                 "created_at": "2024-04-26T12:05:33.456122+00:00",
            //                 "id": 3,
            //                 "isCancelled": false,
            //                 "payment_source": null,
            //                 "payment_status": "pending",
            //                 "total_price": 100.5,
            //                 "updated_at": "2024-04-26T12:05:33.456122+00:00",
            //                 "user_id": "964a6f11-ce97-4fea-98f6-e7d9155dec75",
            //                 "order_plans": [
            //                     {
            //                         "created_at": "2024-04-26T12:05:33.456122+00:00",
            //                         "id": 12,
            //                         "order_id": 3,
            //                         "plan_id": 13,
            //                         "plan_price": 50,
            //                         "plan": {
            //                             "category": "",
            //                             "create_at": "2024-04-04T08:34:18.503611+00:00",
            //                             "description": null,
            //                             "features": [
            //                                 "Weekly Bookkeeping",
            //                                 "Dedicated CPA",
            //                                 "Free State/Extension Filings"
            //                             ],
            //                             "id": 13,
            //                             "is_deleted": false,
            //                             "max_val": 750000,
            //                             "min_val": 1,
            //                             "name": "PRO Bookkeeping",
            //                             "plan_type": "threshold_step",
            //                             "prices": [
            //                                 {
            //                                     "price": "$100",
            //                                     "threshold": 1500
            //                                 },
            //                                 {
            //                                     "price": "$150",
            //                                     "threshold": 8000
            //                                 },
            //                                 {
            //                                     "price": "$300",
            //                                     "threshold": 75000
            //                                 },
            //                                 {
            //                                     "price": "$400",
            //                                     "threshold": 100000
            //                                 },
            //                                 {
            //                                     "price": "$500",
            //                                     "threshold": 150000
            //                                 },
            //                                 {
            //                                     "price": "$650",
            //                                     "threshold": 200000
            //                                 },
            //                                 {
            //                                     "price": "$750",
            //                                     "threshold": 250000
            //                                 },
            //                                 {
            //                                     "price": "$850",
            //                                     "threshold": 350000
            //                                 },
            //                                 {
            //                                     "price": "$1050",
            //                                     "threshold": 500000
            //                                 },
            //                                 {
            //                                     "price": "$1200",
            //                                     "threshold": 750000
            //                                 }
            //                             ],
            //                             "sort_id": 2,
            //                             "type": "Bookkeeping",
            //                             "updated_at": "2024-04-19T14:02:50.735149+00:00"
            //                         },
            //                         "plan_value": 60,
            //                         "updated_at": "2024-04-30T10:32:14.321734+00:00"
            //                     }
            //                 ]
            //             }
            //         ]
            //     }
            // };
            let userDataResponse = null;
            let userId;
            let token;
            if(user.Id && user.token) {
                userId = user.Id;
                token = user.token;
            } else {
                throw new Error("User not found");
            }
            if(!token) {
                token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
            }

            try {
                userDataResponse = await plansApi.getUserPlans(token);
            } catch (error) {
                if(error.status === 401) {
                    token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
                    if(token)
                        userDataResponse = await plansApi.getUserPlans(token);
                } else {
                    throw error;
                }
            }

            if(userDataResponse === null) {
                throw new Error("API failure in userDataResponse");
            }
            let orderPlanJoinData = [];
            let flag = false;
            for (let order of userDataResponse.documents.orders) {
                /**Write conversion of timestamp to parentDir name */
                const parentDir = convertTimestamp(order.created_at) + " UTC";
                if(order.isExpired === false && order.isCancelled === false){
                    flag = true;
                }
                for (let order_plan of order.order_plans) {
                    /**make an object {plan.id, plan.name, order.id, parentDirName, order.created_at} */
                    let orderPlanJoinItem = {
                        parentDir: parentDir,
                        orderId: order.id,
                        createdAt: order.created_at,
                        planId: order_plan.plan.id,
                        planName: order_plan.plan.name,
                        planCategory: order_plan.plan.category
                    };

                    /**Push to orderPlanJoinData */
                    orderPlanJoinData.push(orderPlanJoinItem);
                }

            }

            if(flag === true) {
                setUserHasActivePlans(true);
            }

            setUserOrderPlansJoin(orderPlanJoinData);

        } catch (error) {
            console.error(`ERROR: Failed to load user active plans due to ${error.message ? error.message : error}`);
        }
    }

    const convertTimestamp = (timestamp) => {
        // Parse the input timestamp using moment.js
        const parsedTimestamp = moment(timestamp, moment.ISO_8601).utc();
        
        // Format the timestamp according to the desired format
        const formattedTimestamp = parsedTimestamp.format('YYYY-MM-DD HH:mm:ss');
        
        return formattedTimestamp;
    }


    return ( 
        <FileSystemContext.Provider value = {{userOrderPlansJoin, userHasActivePlans, isLoading, setIsLoading}}>
            {children}
        </FileSystemContext.Provider>
     );
}

export default FileSystemProvider;


