import { useLocation } from "react-router-dom";
import routeUtils from "../Utils/RouteUtils";

function NotFound() {
    const location = useLocation();
    return (
        <>
            {routeUtils.checkIfProtectedRoute(location.pathname) === true ?
                <div>
                    401 | Login to view this page
                </div>
                : <div>
                    404 | Page not found
                </div>}
        </>
    );
}
export default NotFound;