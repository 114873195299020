import '../Css/OtherServices.css';
import Accordion from '../context/Accordion';
import '../Css/BookKeeping.css';
import '../Css/Accordion.css';
import '../Css/BusinessTax.css';
import businesstax from '../Assets/businesstax.png'
import '../Css/section-pages.css'
import { useEffect } from 'react';

const BusinessTax = () => {
    const accordionItems = [
        { title: 'Meet your dedicated bookkeeping expert', content: "Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows." },
        { title: 'Mend your transactions - automatically', content: "Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows." },
        { title: 'Get your financial report, get back to business', content: "Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows." },
        { title: 'Add tax or CFO services, if you need it', content: "Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows." },
    ];

    useEffect(()=> {
        const description = "Easily manage tax planning, compliance, and reporting by utilizing our knowledgeable services. Usespyder provides comprehensive business tax solutions, ranging from State and Local Tax (SALT) to careful tax preparation."
        document.title = "Business Tax Service | Usespyder";
        document.querySelector('meta[name="description"]').setAttribute("content", description);
      }, []);
    return (
        <div>
            <img src={businesstax} width='100%' />
            <h2 className="bookkeeping_title">Your one-stop destination for all your business tax needs</h2>
            <p className="bookkeeping_desc">At Spyder we offer a comprehensive range of business tax services that are tailored to meet the specific needs of our clients. Whether you are a small business owner or a large enterprise, our team of experienced tax professionals is equipped to handle all your tax concerns with accuracy and efficiency. We stay up-to-date with the latest tax laws and regulations to provide you with the most accurate and effective solutions.</p>
            {/* <div className="business_tax_container">
                <h2 className="bookkeeping_question">Why companies like yours choose Spyder</h2>
                <div className="bookkeeping_section businesstax_section">
                    <div className="businesstax-point">
                        <h5>Tax Planning and Consulting</h5>
                        Our tax planning and consulting services are designed to help businesses minimize their tax liabilities and maximize their tax savings. Our team works closely with clients to understand their business and financial goals and devise a tax plan that is aligned with their objectives. With our practical and strategic approach, we ensure that our clients take advantage of all available tax deductions and credits while staying compliant with tax laws.
                    </div>
                    <div className="businesstax-point">
                        <h5>Tax Preparation</h5>
                        Preparing and filing taxes can be a time-consuming and daunting task for business owners. Our tax preparation services take the burden off your shoulders and ensure that your tax returns are accurately and timely filed. We have a thorough understanding of the ever-changing tax laws and use the latest software to prepare tax returns, minimizing the chances of errors and maximizing the chances of getting a tax refund.
                    </div>
                    <div className="businesstax-point">
                        <h5>{`State and Local Tax (SALT) Services`}</h5>
                        Navigating through state and local tax laws can be complex, especially for businesses that operate in multiple states. Our SALT services include sales and use tax, business licensing, and compliance with state and local tax regulations. We help businesses ensure that they are meeting their tax obligations in all the jurisdictions they operate in.
                    </div>
                    <div className="businesstax-point">
                        <h5>Tax Compliance and Reporting</h5>
                        Keeping up with tax compliance requirements is crucial for any business to avoid penalties and interest. Our team at Spyder Business Solutions helps businesses stay on top of their tax compliance by providing services such as payroll tax compliance, Form 1099 preparation, and business license renewals.
                    </div>
                </div>

            </div> */}

            <div className="bullet-point-container">
                <div className="bullet-point-card">
                    <div className="card-heading">
                        Why companies like yours choose Spyder?
                    </div>
                    <div className="bullet-point-item-card">
                        <div className="bullet-point-item">
                            <div className="bullet-point-item-flex-container">
                                <div className="bullet-point-item-heading">Tax Planning and Consulting</div>
                                Our tax planning and consulting services are designed to help businesses minimize their tax liabilities and maximize their tax savings. Our team works closely with clients to understand their business and financial goals and devise a tax plan that is aligned with their objectives. With our practical and strategic approach, we ensure that our clients take advantage of all available tax deductions and credits while staying compliant with tax laws.
                            </div>

                        </div>
                        <div className="bullet-point-item">
                            <div className="bullet-point-item-flex-container">
                                <div className="bullet-point-item-heading">Tax Preparation</div>
                                Preparing and filing taxes can be a time-consuming and daunting task for business owners. Our tax preparation services take the burden off your shoulders and ensure that your tax returns are accurately and timely filed. We have a thorough understanding of the ever-changing tax laws and use the latest software to prepare tax returns, minimizing the chances of errors and maximizing the chances of getting a tax refund.
                            </div>
                        </div>
                        <div className="bullet-point-item">
                            <div className="bullet-point-item-flex-container">
                                <div className="bullet-point-item-heading">{`State and Local Tax (SALT) Services`}</div>
                                Navigating through state and local tax laws can be complex, especially for businesses that operate in multiple states. Our SALT services include sales and use tax, business licensing, and compliance with state and local tax regulations. We help businesses ensure that they are meeting their tax obligations in all the jurisdictions they operate in.
                            </div>
                        </div>
                        <div className="bullet-point-item">
                            <div className="bullet-point-item-flex-container">
                                <div className="bullet-point-item-heading">Tax Compliance and Reporting</div>
                                Keeping up with tax compliance requirements is crucial for any business to avoid penalties and interest. Our team at Spyder Business Solutions helps businesses stay on top of their tax compliance by providing services such as payroll tax compliance, Form 1099 preparation, and business license renewals.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='businesstax-footer-container'>
                <p className="bookkeeping_desc">With Spyder you can rest assured that your business taxes are in capable hands. We are committed to providing personalized, reliable, and efficient tax services to help our clients prosper and grow. </p>
                <p className="bookkeeping_desc">Contact us today to learn more about how we can assist you with your business tax needs. Let us handle your taxes while you focus on running your business.</p>
            </div>

        </div>
    );
}

export default BusinessTax;