import React, { createContext, useContext, useState } from "react";
import { UserAuth } from "./AuthProvider";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cart, setCart] = useState({});
  const [cartPlans, setCartPlans] = useState([]);
  const {user} = UserAuth();

  //If User is logged in load previous cart
  const loadCart = async() => {

  }

  //Add plan to cart
  const addCartPlan = async(plan) => {
    //Add items to cart and add to DB
    const newCartPlans = [...cartPlans];
    const planExisting = newCartPlans.find((cartPlan) => cartPlan.id === plan.id);
    if(!planExisting) {
      newCartPlans.push({...plan});
    }
    setCartPlans(newCartPlans);
  }

  //Remove plan from cart
  const removeCartPlan = async(planId) => {
    //Remove item from cart and delete entry from DB
    let filteredPlans = cartPlans.filter((cartPlan) => cartPlan.id !== planId);
    setCartPlans(filteredPlans);
  }

  //Update plan value in cart
  const  updateCartPlanValue = async(planId, value, value1, plan_price) => {
    //Update cart plan with new value
    let updatedCartPlans = [...cartPlans];
    updatedCartPlans = updatedCartPlans.map((cartPlan) => {
      if(cartPlan.id === planId){
        let newCartPlan = {...cartPlan};
        newCartPlan.value = parseInt(value);
        newCartPlan.value1 = parseInt(value1);
        newCartPlan.plan_price = plan_price;
        return newCartPlan;
      } else {
        return cartPlan;
      }
    });
    setCartPlans(updatedCartPlans);
  }

  const toggleCartOpen = () => {
    setIsCartOpen(!isCartOpen);
  }

  const resetCart = () => {
    setCartPlans([]);
  }


  return (
    <CartContext.Provider value={{ isCartOpen, cartPlans, resetCart, loadCart, addCartPlan, removeCartPlan, updateCartPlanValue, toggleCartOpen, setIsCartOpen }}>
      {children}
    </CartContext.Provider>
  );
};
