import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth'; import userApis from '../Apis/UserApi';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import apiRegenerateToken from '../Utils/ApiRegenerateToken';
import Modal from "react-modal";
import '../Css/Layout.css';
import { ColorRing } from 'react-loader-spinner';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [newUser, setNewUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "91%",
      maxWidth: "35rem",
      padding: 0,
      background: "transparent",
      color: "white",
      border: "0px solid transparent"
    },
    overlay: {
      background: "rgba(166,166,166,0.65)",
      zIndex: 999
    }
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
    // signInWithRedirect(auth, provider)
  };

  const logOut = () => {
    signOut(auth);
    navigate('/');
  }

  useEffect(() => {
    console.log("user set: ", user);
    if (newUser && user !== null && user.token) {
      navigate('/profile');
      setNewUser(false);
    }
  }, [user, newUser])

  const setUserInformationFromDbByAuthServiceId = async (authServiceId, email) => {
    //Set Loader to true
    setIsLoading(true);
    let tempUser = await userApis.getUserByEmail(email);
    if (tempUser === null) {
      tempUser = await userApis.registerUserByFirebase(email, authServiceId);
      console.log("New user: ", tempUser);
      if (tempUser === null) {
        tempUser = await userApis.getUserByEmail(email, false);
      }
      console.log("New User already registered: ", tempUser);
      // setUser(user);
      // console.log("User is: ", user);
      setNewUser(true);
    }
    setUserAndToken(tempUser);
    setIsLoading(false);
  }

  const setUserAndToken = async (currentUser) => {
    try {
      const token = await userApis.generateDocAccessToken(currentUser.Id);
      currentUser.token = token;
      setUser(currentUser);
    } catch (error) {
      logOut();
      console.error(`Could not set user and token due to ${error.message ? error.message : error}`)
    }
  }

  useEffect(() => {
    //This useEffect will only be called once when component mounts as dependency array is empty. But onAuthStateChanged is a listener attached to internal auth 
    //state maintained by firebase on client side. Whenever user state changes it will run the callback function defined which will set the user.
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      //If firebase user exists, code to register new user or get user from db and set user as that user
      if (currentUser) {
        console.log("currentUser", currentUser);
        const authServiceId = currentUser.uid;
        const email = currentUser.email;
        setUserInformationFromDbByAuthServiceId(authServiceId, email);
      }
      else {
        setUser(currentUser);
      }
    });
    return () => {
      unsubscribe();
    };

    /**
     * Setting a user as a proxy static data for testing. Remove after testing 
     */
    // const currentUser = {
    //   "Id": "964a6f11-ce97-4fea-98f6-e7d9155dec75",
    //   "address": "",
    //   "auth_service_id": "A9dKmfcI6hOUqxVdJqKBcuTnLL52",
    //   "city": "",
    //   "country": "",
    //   "created_at": "2024-04-02T14:18:18.706672+00:00",
    //   "email": "dwaipayan.vidyanta+1@digi-prex.com",
    //   "first_name": "",
    //   "is_deleted": false,
    //   "last_name": "",
    //   "password": "",
    //   "phone_number": "",
    //   "state": "",
    //   "updated_at": "2024-04-02T14:18:18.706672+00:00",
    //   "zip_code": "",
    //   "role": "user"
    // }

    // setUserAndToken(currentUser);
    // setUser(null);
  }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user, setUser }}>
      {children}
      <Modal isOpen={isLoading} onRequestClose={() => {
      }}
        style={customStyles}>
        <div className='loader-container'>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#ffffff', '#ffffff', '#6C89B1', '#ffffff', '#ffffff']}
          />
        </div>
      </Modal>
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};