import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Cart from "../Cart";
import { IoCloudyNight } from "react-icons/io5";
import SideMenu from "./SideMenu";
import '../../Css/Layout.css';
import { useState } from "react";
import routeUtils from "../../Utils/RouteUtils";

function Layout(){
    const location = useLocation();
    return (
        <div className="App">
            
            <Header />
            <Cart />
            <div className="layout-container">
                {routeUtils.checkIfProtectedRoute(location.pathname) && <SideMenu/>}
                <Outlet />
            </div>
            
            
            <Footer />
        </div>
    );
}
export default Layout;
