import moment from 'moment';
import DOCUMENT_COL from '../Constant/DocumentComponent';
import '../Css/DocumentComponent.css';
import { UserAuth } from '../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ellipsis from '../Assets/ellipsis.png';
import documentIcon from '../Assets/document.png';
import folder from '../Assets/folder.png';
import apiRegenerateToken from '../Utils/ApiRegenerateToken';
import documentApis from '../Apis/DocumentApis';

const DocumentComponent = ({ type, obj, userData, setUserData }) => {
    const { user, setUser, logOut } = UserAuth();
    const navigate = useNavigate()

    const getFormattedTimestamp = (timestamp) => {
        return ("timestamp:", moment(timestamp).format("LLL"))
    }

    const [openSubMenu, setOpenSubMenu] = useState(false);

    const toggleSubmenu = () => {
        setOpenSubMenu(!openSubMenu);
    }

    const trimText = (text) => {
        if (text)
            return (text.length <= 30 ? text : (text.substring(0, 30) + "..."));
        return text
    }

    const showFile = async (documentKey) => {
        try {
            let response = null;
            let userId;
            let token;
            if (user.Id && user.token) {
                userId = user.Id;
                token = user.token;
            } else {
                throw new Error("User not found");
            }
            if (!token) {
                token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
            }
            try {
                response = await documentApis.getDocumentFile(token, documentKey);
            } catch (error) {
                if (error.status === 401) {
                    token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
                    if (token)
                        response = await documentApis.getDocumentFile(token, documentKey);
                } else {
                    throw error;
                }
            }

            if (response === null) {
                throw new Error("API failure in getDocument");
            }

            if (response.data) {
                // const temporaryUrl = URL.createObjectURL(response.data);
                // // Open the downloaded file in a new tab
                // window.open(temporaryUrl, "_blank");

                // // Release the temporary URL after opening the new tab
                // URL.revokeObjectURL(temporaryUrl);
                // Create a temporary link element
                console.log("presignedUrl:", response.data.presignedUrl )
                const link = document.createElement("a")
                link.href = response.data.presignedUrl;
                link.setAttribute('download', ''); // This attribute prompts the browser to download the file
                // link.setAttribute('target', '_blank')
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }
        } catch (error) {
            console.error(`ERROR: Failed to load user document due to ${error.message ? error.message : error}`);
        }

    }

    const setDocumentDeleteFlag = async (documentId, deleteFlag) => {
        try {
            let response = null;
            let userId;
            let token;
            if (user.Id && user.token) {
                userId = user.Id;
                token = user.token;
            } else {
                throw new Error("User not found");
            }
            if (!token) {
                token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
            }
            try {
                response = await documentApis.setDocumentDeleteFlag(token, documentId, deleteFlag);
            } catch (error) {
                if (error.status === 401) {
                    token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
                    if (token)
                        response = await documentApis.setDocumentDeleteFlag(token, documentId, deleteFlag);
                } else {
                    throw error;
                }
            }

            if (!response) {
                throw new Error("API failure in setting Document delete flag");
            }

            /**Remove document id from userdata state */
            const newUserData = userData.filter((doc) => doc.id !== documentId);
            setUserData(newUserData);
            setOpenSubMenu(false);
            
        } catch (error) {
            console.error(`ERROR: Failed to set document delete flag to ${deleteFlag} due to ${error.message ? error.message : error}`);
        }
    }
    return (
        <>
            {type === "heading" &&
                <div className="heading">
                    <div className="line-col-1 heading-text">{DOCUMENT_COL.heading.name}</div>
                    <div className="line-col-2">{DOCUMENT_COL.heading.createdAt}</div>
                </div>
            }
            {type === "folder" &&
                <div className="folder">
                    <div className="line-col-1" onClick={() => {
                        if (obj.to === "plan") {
                            navigate(`/documents/${obj.id}`);
                        }
                        if (obj.to === "file") {
                            navigate(`/documents/${obj.id}/${obj.name}`)
                        }
                    }}>
                        <img src={folder} alt="" className="folder-icon" />
                        <div>{trimText(obj.name)}</div>
                    </div>
                    <div className="line-col-2">{getFormattedTimestamp(obj.createdAt)}</div>
                </div>
            }
            {type === "file" &&
                <div className="file">
                    <div className="line-col-1" onClick={() => {
                        showFile(obj.docKey)
                    }}>
                        <img src={documentIcon} alt="" className="file-icon" />
                        <span>{trimText(obj.docTitle)}</span>
                    </div>
                    <div className="line-col-2">{getFormattedTimestamp(obj.createdAt)}</div>
                    <div className="options-icon-container">
                        <img src={ellipsis} alt="" className="options-icon" onClick={() => toggleSubmenu()} />
                        {openSubMenu && <div className="option delete" onClick = {() => {setDocumentDeleteFlag(obj.docId, true)}}>Delete</div>}
                    </div>
                </div>
            }

            {type === "bin" &&
                <div className="file">
                    <div className="line-col-1" onClick={() => {
                        showFile(obj.docKey)
                    }}>
                        <img src={documentIcon} alt="" className="file-icon" />
                        <span>{trimText(obj.docTitle)}</span>
                    </div>
                    <div className="line-col-2">{getFormattedTimestamp(obj.createdAt)}</div>
                    <div className="options-icon-container">
                        <img src={ellipsis} alt="" className="options-icon" onClick={() => toggleSubmenu()} />
                        {openSubMenu && <div className="option delete" onClick = {() => {setDocumentDeleteFlag(obj.docId, false)}}>Restore</div>}
                    </div>
                </div>
            }
        </>
    );
}

export default DocumentComponent;