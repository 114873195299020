const RouteUtils = () => {
    const checkIfProtectedRoute = (pathname) => {
        const includedpaths = ['/profile', '/documents', '/bin'];
        for(let path of includedpaths) {
            if(pathname.includes(path)) {
                return true;
            }
        }
        return false;
    }

    return {checkIfProtectedRoute}
}

const routeUtils = RouteUtils();
export default routeUtils;