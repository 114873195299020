import "../Css/ValueInput.css";
import plus from "../Assets/Plus Square.png";
import minusLight from "../Assets/Minus Light.png";
import plusLight from "../Assets/Plus Square light.png";
import minus from "../Assets/Minus.png"
import '../Css/Counter.css';
import { useEffect } from "react";
const ValueInputComponent = ({
    value,
    setValue,
    min_val,
    max_val
}) => {

    const containsOnlyDigits = (inputString) => {
        return /^\d+$/.test(inputString);
    }

    useEffect(()=> {
        setValue(value);
    }, [value])
    return (
        <div className="value-input-container">
            {/* <p><b>Input the number of transactions ranging from {min_val} - {max_val}</b></p> */}
            <div className="value-counter-input-div">
                <label for="value-input" className="value-input-text">No. of transactions</label>
                <div className="value-input-div">
                    <div className="count-button" onClick={() => {
                        if (value !== min_val) {
                            setValue(value - 1);
                        }
                    }} >
                        {(isNaN(value) || value === min_val) && <img src={minusLight} alt="" className={`${value === min_val ? "disable" : ""} button-img value-action-img`} />}
                        {!isNaN(value) && value !== min_val && <img src={minus} alt="" className={`${value === min_val ? "disable" : ""} button-img value-action-img`} />}
                    </div>

                    <input type = "number" inputMode = "numeric" name = "value-input" value={value} className="value-input" placeholder={0} min={min_val} max={max_val} onInput={(event) => {
                        let eventValue = event.target.value;
                        console.log('eventValue', eventValue);
                        if (eventValue === '') {
                            setValue(eventValue);
                            return;
                        }
                        else if (containsOnlyDigits(eventValue.toString())) {
                            eventValue = Math.min(max_val, eventValue);
                            eventValue = Math.max(min_val, eventValue);
                            setValue(eventValue);
                        }
                    }} />
                    <div className="count-button" onClick={() => {
                        if (value !== max_val) {
                            if(isNaN(value)){
                                setValue(1);
                            } else {
                                setValue(value + 1);
                            }
                        }
                    }} >
                        {value === max_val && <img src={plusLight} alt="" className={`${value === max_val ? "disable" : ""} button-img`} />}
                        {value !== max_val && <img src={plus} alt="" className={`${value === max_val ? "disable" : ""} button-img`} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ValueInputComponent;