import userApis from "../Apis/UserApi";

const ApiRegenerateToken = () => {
    const regenerateToken = async (userId, setUser, user, logOut) => {
        try {
            if(!userId) {
                throw {
                    status: 404
                }
            }
            let tokenData = await userApis.generateDocAccessToken(userId);
            setUser({ ...user, token: tokenData });
            return tokenData;
        } catch (error) {
            /**If token could not be generated */
            if(error.response) {
                logOut();
                throw error;
            } else {
                console.error("Something unexpected occured");
            }
        }
    }

    return {regenerateToken}
}

const apiRegenerateToken = ApiRegenerateToken();
export default apiRegenerateToken;