import React, { useCallback, useEffect, useState, useRef } from "react";
import "../Css/Personaltax.css";
import { FaCheck, FaCheckCircle  } from "react-icons/fa";
import Applicableoptions from "../Constant/Personaltax";
import useModal from "../hooks/useModal";


const OptionsBlankArray = Applicableoptions.options.map(()=> false);

 function PersonalTax() {
    const [selectedTypes, setSelectedTypes] = useState(OptionsBlankArray);
    const [documents, setDocuments] = useState([]);
    const documentsListRef = useRef(null);
    const {setIsCartSubmit, setModalVisibility} = useModal();

    const onOptionClick = useCallback((i)=>{
        setSelectedTypes(options => options.map((val,index) => {
            if(i === index){
                return !val;
            }
            return val;
        }))
    },[]);

    useEffect(()=> {
        const description = "Possess a house or rental property? For professional personal tax services catered to your particular circumstances, trust Usespyder. Join us in streamlining your tax journey."
        document.title = "Personal Tax Service | Usespyder";
        document.querySelector('meta[name="description"]').setAttribute("content", description);
      }, []);

    useEffect(()=>{
        let docs = selectedTypes.map((selected,i)=> selected && Applicableoptions.options[i].documents).filter((doc)=> doc ? true : false);
        docs = docs.flat();
        setDocuments([...Applicableoptions.documents, ...docs]);
    },[selectedTypes]);

    const scrollToDocuments = () => {
        if (documentsListRef.current) {
            const headerHeight = document.getElementById('header').offsetHeight;

            // Calculate the position to scroll to with padding
            const scrollToPosition = documentsListRef.current.offsetTop - headerHeight + 5;
            // Scroll the div into view with padding
            window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
        }
    };

    return(
        <div>
            <div>
                <h1>Life changes quickly and so can your tax documents</h1>
                <h4>Select the applicable options to identify the required documents for your tax filing.</h4>
            </div>
            <div className="tax_options_list">
                {
                    Applicableoptions.options.map(({ title, icon}, index)=> {
                        return (
                            <div className={`option ${selectedTypes[index] ? 'checked' : ''}`} key={"personal_tax_type_"+index} onClick={()=>onOptionClick(index)}>
                                <div className="checkbox">
                                    <FaCheck className="check_icon"/>
                                </div>
                                <div className="content"><label>{title}</label></div>
                                <div><img className="icon" src={icon}/></div>
                            </div>
                        );
                    })
                }
            </div>
            <button className="documents_button" onClick={scrollToDocuments}>Get my document checklist</button>
            <div className="document_list_section" ref={documentsListRef}>
                <h2>The documents you’ll need</h2>
                <div className="documents_list">
                    {
                        documents.map((doc,index)=>(
                            <div key={"doc_"+index}><div><FaCheckCircle className="icon"/></div><span className="name">{doc}</span></div>
                        ))
                    }
                </div>

                <div className="talk-to-us-button-container">
                    <button className='section1_button' onClick={() => {
                            setIsCartSubmit(false);
                            setModalVisibility(true);
                            }}>Talk to an expert</button>
                </div>
            </div>
        </div>
    )
    
 }

 export default PersonalTax;