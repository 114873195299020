const services ={
    service1 : {
     "title": "Bookkeeping",
     "description":"You get to focus on running your business while we reconcile all your financial statements and do your books.",
     "button":"Get bookkeeping"
    },
    service2 : {
     "title": "Tax",
     "description":"You get to focus on running your business while we reconcile all your financial statements and do your books.",
     "button":"Get your taxes prepared"
    },
    service3 : {
     "title": "CFO",
     "description":"We can help with budgeting, KPI tracking, forecasting and much more. Our financial experts help you get insights into your business.",
     "button":"Get bookkeeping"
    },
    service4 : {
        "title": "R&D Tax Credit",
        "description":"Save up to $500,000 per year. We handle the entire R&D tax claim. process for you.",
        "button":"Claim your R&D credit "
       }
 }
 
 export default services
 
 