import React, { useEffect } from "react";
import Accordion from '../context/Accordion';
import '../Css/Accordion.css'

const FractionalCFOTest = ({ data }) => {
    const { banner, title, description, first_section, second_section } = data;

    const accordionItems = second_section ? second_section.answers.map((answer, index) => ({
        title: answer.question,
        content: answer.answer
    })) : [];

    useEffect(()=> {
        const description = "Access expert financial leadership without the full-time commitment. Our fractional CFO service at Usespyder offers strategic financial guidance suited to your business's needs."
        document.title = "Fractional CFO Service | Usespyder";
        document.querySelector('meta[name="description"]').setAttribute("content", description);
      }, []);

    return (
        <div>
            <img src={banner} width='100%' alt="CFO Banner"/>
            <h2 className="bookkeeping_title">{title}</h2>
            <p className="bookkeeping_desc">{description}</p>
            {/* <div className="bookkeeping_container">
                <h2 className="bookkeeping_question">{first_section.title}</h2>
                <div className="bookkeeping_section fractional-cfo-section">
                    {first_section.answers.map((answer, index) => (
                        <div key={index} className="bookkeeping_point">
                            <h5>{answer.title}</h5>
                            {answer.description}
                        </div>
                    ))}
                </div>
            </div> */}
            <div className="bullet-point-container">
                <div className="bullet-point-card">
                    <div className="card-heading">
                    Want to unlock more growth? Bring in the expert CFOs.
                    </div>
                    <div className="bullet-point-item-card">
                        <div className="bullet-point-item">
                            <div className="bullet-point-item-flex-container bookkeeping-text">
                                <div className="bullet-point-item-heading bookkeeping-heading">Boost Your Growth</div>
                                Develop financial strategies and models to enhance cash flow and profitability, covering everything from customer cohort analysis to sales efficiency metrics such as LTV and CAC.
                            </div>

                        </div>
                        <div className="bullet-point-item">
                            <div className="bullet-point-item-flex-container bookkeeping-text">
                                <div className="bullet-point-item-heading bookkeeping-heading">Enhance Financial Oversight</div>
                                Take charge of your company's financial trajectory with precise forecasts and budgets. We construct these step-by-step, from revenue projections to net income estimations.
                            </div>
                        </div>
                        <div className="bullet-point-odd-item">
                            <div className="bullet-point-item odd-item">
                                <div className="bullet-point-item-flex-container bookkeeping-text">
                                    <div className="bullet-point-item-heading bookkeeping-heading">Sidestep Expensive Mistakes</div>
                                    Set up a robust financial framework and practices primed for expansion and investor appeal, covering everything from invoicing to expense management.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {second_section && (
                <div>
                    <h1 className="accordion-title">{second_section.title}</h1>
                    <Accordion items={accordionItems} />
                </div>
            )}
        </div>
    );
}

export default FractionalCFOTest;
