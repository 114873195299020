import '../Css/UploadDocumentModal.css';
import closeIcon from '../Assets/multiply.png';
import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import tickok from '../Assets/lottie_animations/tickok.json'
import { useParams } from 'react-router-dom';
import apiRegenerateToken from '../Utils/ApiRegenerateToken';
import documentApis from '../Apis/DocumentApis';
import { UserAuth } from '../context/AuthProvider';
const UploadDocumentModal = ({
    setIsUploadModalOpen,
    setUserPlanDocs
}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [docTitle, setDocTitle] = useState('');
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [showAnimation, setShowAnimation] = useState(false);
    const params = useParams();
    const { user, setUser, logOut } = UserAuth();

    const uploadFormSubmit = async(fileTitle, file) => {
        try {
            setIsDisabled(true);
            let response = null;
            let userId;
            let token;

            const planId = params.planId;
            const parentDir = params.parentDir;
            if (user.Id && user.token) {
              userId = user.Id;
              token = user.token;
            } else {
              throw new Error("User not found");
            }
            if (!token) {
              token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
            }
      
            try {
              response = await documentApis.uploadDocument(token, fileTitle, file, parentDir, planId);
          } catch (error) {
              if (error.status === 401) {
                  token = await apiRegenerateToken.regenerateToken(userId, setUser, user, logOut);
                  if(token)
                    response = await documentApis.uploadDocument(token, fileTitle, file, parentDir, planId);
              } else {
                  throw error;
              }
          }
      
          if(response.success === true) {
            /**Set User documents again */
            await setUserPlanDocs(planId, parentDir, false);
            showAcknowledgementResponse();
          } else {
            setSubmitError(true);
          }
      
          } catch (error) {
            console.error(`ERROR: Failed to fetch user documents due to ${error.message ? error.message : error}`);
            setSubmitError(true);
          } finally {
            setIsDisabled(false);
          }
    }

    const showAcknowledgementResponse = () => {
        setShowAnimation(true);
        setTimeout(()=> {
            setIsUploadModalOpen(false);
            setShowAnimation(false);
        }, 5000)
    }
    useEffect(() => {
        if(!validateForm()) 
            setIsDisabled(true);
        else {
            setIsDisabled(false);
        }
    }, [selectedFile, error])

    const validateForm = () => {
        if(!error && selectedFile !== null) return true;
        return false;
    }

    const handleFileChange = (file) => {
        if (file && file.size > 15 * 1024 * 1024) { // 15 MB in bytes
            setError(true);
            setErrorText('File Size Exceeding 15 MB')
          }
        // else if(file && file.type !== "text/csv") {
        //     setError(true);
        //     setErrorText('Only .csv files are allowed')
        // }
        else {
            setSelectedFile(file);
            setError(false);
            setErrorText('');
          }
    }
    return ( 
        <div className="upload-modal-container">
            {!showAnimation && <div className="upload-modal-card">
                <div className="upload-modal-card-title">
                    <div className="upload-modal-card-title-text">- Upload a File</div>
                    <div>
                        <img src={closeIcon} alt="close icon" className="modal-form-close-button" onClick={() => {setIsUploadModalOpen(false)}} />
                    </div>
                </div>
                <div className="upload-modal-card-body">
                    <div className="upload-form-group">
                        {/* <span className="info-mute">Only csv files are allowed</span> */}
                        <input type="text" className="upload-form-control" placeholder="Document Title (Optional)" onChange={(event) => setDocTitle(event.target.value)}/>
                    </div>
                    <div className="upload-form-group">
                        <input type="file" className="upload-form-control" onChange = {(event) => handleFileChange(event.target.files[0])}/>
                        {error && <span className="error-text">{errorText}</span>}
                    </div>

                    <div className="upload-form-group">
                    <button className={`submitUpload ${isDisabled === true? "disabled": ""}`} onClick={() => {
                        if(isDisabled === true) {}
                        else{
                            uploadFormSubmit(docTitle, selectedFile)
                        }
                    }}>UPLOAD</button>
                    {submitError && <span className="error-text">Something went wrong, please try again later</span>}
                    </div>   

                </div>
            </div>}

            {showAnimation && <div className="thank-you-container">
                <div className='thank-you-animation-container'>
                    <Lottie animationData={tickok} className = "thank-you-tickok" loop autoplay></Lottie>
                </div>
                <div className="thank-you-message">
                        {`File has been successfully uploaded`}
                </div>
            </div>}
        </div>
     );
}

export default UploadDocumentModal;