import { createContext, useEffect, useState } from "react";
import plansApi from "../Apis/PlansApis";
//import plans from "../Constant/Plans";

export const PlansContext = createContext();

const PlansProvider = ({children}) => {
    const [allPlans, setAllPlans] = useState([]);

    const updatePlan = (planId, value, value1, plan_price) => {
        let updatedPlans = [...allPlans];
        updatedPlans = updatedPlans.map((plan) => {
            if(plan.id === planId) {
                let newPlan = {...plan};
                newPlan.value = parseInt(value);
                newPlan.value1 = parseInt(value1);
                newPlan.plan_price = plan_price;
                return newPlan;
            } else {
                return plan;
            }
            
        });
        setAllPlans(updatedPlans);
    }

    const loadPlans = async() => {
        const plans = await plansApi.getAllPlans();
        for(let plan of plans) {
            
            plan.value = plan.min_val;
            if(plan.plan_type === "baseline_increment") {
                plan.value1 = plan.prices[0].threshold;
            } else {
                plan.value1 = plan.min_val;
            }
            if(plan.prices[0].price.match(/\d+/g) === null) {
                plan.plan_price = "0";
            } else {
            //Ensure prices[0].price has only one number
            plan.plan_price = plan.prices[0].price.match(/\d+/g).join('');
            }

        }
        setAllPlans([...plans]);
    }
    useEffect(() => {
        loadPlans();
    }, []);
    return (  
        <PlansContext.Provider value={{ allPlans, updatePlan }}>
            {children}
        </PlansContext.Provider>
    );
}

export default PlansProvider;