import React, { useContext } from "react";
import footer from "../../Constant/Footer";
import '../../Css/Footer.css'
import footer_logo from '../../Assets/Spyder Logo.png'
import useModal from "../../hooks/useModal";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartProvider";
import { PageContext } from "../../context/PageProvider";

const Footer = () => {
    const { setModalVisibility, setIsCartSubmit } = useModal();
    const { activeMobileTab, setActiveMobileTab } = useContext(PageContext);
    const { cartPlans } = useCart();
    const navigate = useNavigate();
    const navigateToLink = (key) => {
        console.log("Key", key);
        switch (key) {
            case "About":
                setActiveMobileTab("home");
                break;
            case "Pricing":
                setActiveMobileTab("pricing");
                break;
            default:
                break;
        }
    }
    return (
        <div>
            <div className='footer_section1'>
                {/* <div className="footer_title">See what sage can do for you</div>
                <div className="footer_input_section">
                    <input className="footer_input"/>
                    <button className="footer_button">Talk to an expert</button>
                </div> */}
            </div>
            <div className='footer_bottom'>
                <div className='footer_bottom_section1'>
                    <img src={footer_logo} className="footer_logo" onClick={() => {
                        window.scrollTo(0, 0);
                        navigate('/')
                    }} />
                    <div className="footer_contact">
                        {Object.entries(footer.support).map(([key, value]) => {
                            if (key === "phone") {
                                return <p>Phone: <a className="support-links" key={key} href={`tel:${value}`}>{value}</a></p>
                            }
                            if (key === "email") {
                                return <p>Email: <a className="support-links" key={key} href={`mailto:${value}`}>{value}</a></p>
                            }
                            if (key === "address") {
                                return <p key={key} dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, '<br>') }} class='footer-address'/>
                            }
                        })}
                    </div>
                </div>
                <div className='footer_section2'>
                    <div className="footer_menus">
                        {Object.entries(footer.menu).map(([section, links]) => (
                            <div key={section} className='footer_menu_section'>
                                <div className="footer_menu_title">{section}</div>
                                <ul>
                                    {Object.entries(links).map(([key, value]) => (
                                        <li key={key} className="footer_menu_items">

                                            {key !== "Contact Us" ? <Link to={value} onClick={() => {
                                                window.scrollTo(0, 0);
                                                navigateToLink(key);
                                            }
                                            }>{key}</Link> : <div style={{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                setIsCartSubmit(false);
                                                setModalVisibility(true);

                                            }}>{key}</div>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`${cartPlans.length > 0 ? "mobile-padding" : ""}`}></div>
        </div>
    )
}

export default Footer;