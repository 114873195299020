import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useEffect, useState } from 'react';
import '../Css/TalkToUs.css';
import closeIcon from '../Assets/multiply.png';
import redirectIcon from '../Assets/upper right arrow.png';
import useModal from "../hooks/useModal";
import { useCart } from '../context/CartProvider';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import formSparkApi from '../Apis/FormSparkApis';
import Lottie from 'lottie-react';
import tickok from '../Assets/lottie_animations/tickok.json'
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthProvider';

const TalkToUsComponent = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [country, setCountry] = useState('US');
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [showSubmitAnimation, setShowSubmitAnimation] = useState(false);
    const { isCartSubmit, setModalVisibility } = useModal();
    const [disabled, setDisabled] = useState(true);
    const {cartPlans, resetCart, toggleCartOpen} = useCart();
    const {user} = UserAuth();
    const navigateTo = useNavigate();

    const showThankYouResponse = () => {
        setShowSubmitAnimation(true);
        setTimeout(()=> {
            if(isCartSubmit) {
                toggleCartOpen();
                window.scrollTo(0, 0);
                navigateTo('/')
            }
            setModalVisibility(false);
        }, 5000)
    }

    const handleConsultationRequest = async() => {
        const userId = user ? user.Id: "";
        setDisabled(true);
        const userReqObj = {
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber
        };

        await formSparkApi.submitConsultationForm(userReqObj);
        setDisabled(false);
        showThankYouResponse()
    }

    const handleCartSubmissionRequest = async() => {
        const userId = user ? user.Id: "";
        setDisabled(true);
        const userReqObj = {
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber
        };
        const cart = cartPlans;
        await formSparkApi.submitCartForm(cart, userReqObj);
        setDisabled(false);
        resetCart();
        showThankYouResponse();
    }

    const handleSetPhoneNumber = (value) => {
        if(value) {
            setPhoneNumber(value);
            setPhoneNumberError(true);
        }
    }

    const validateInput = () => {
        if(firstName && lastName && validateEmail() && validatePhoneInput()) {
            return true;
        } else {
            return false;
        }
    }

    const validateEmail = () => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    const validatePhoneInput = () => {
        console.log("phonenumber", phoneNumber);
        console.log("country", country);
        return isPossiblePhoneNumber(phoneNumber, country);
    }

    useEffect(() => {
        if(validateInput()){
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [firstName, lastName, phoneNumber, email])

    return (
        <div className="modal-form-container">
            {!showSubmitAnimation && <div className="modal-form-card">
                <div className="modal-form-header">
                    {isCartSubmit && <div className="modal-form-title"> - Fill details to save cart!</div>}
                    {!isCartSubmit && <div className="modal-form-title"> - Talk to us!</div>}
                    <div>
                        <img src={closeIcon} alt="close icon" className="modal-form-close-button" onClick={() => setModalVisibility(false)} />
                    </div>

                </div>
                <div className="modal-form-body">
                    <div className="modal-form-group">
                        <div className="modal-form-control-container">
                            <input type="text" name="first-name" placeholder="First Name*" className='modal-form-control' value = {firstName} onInput = {(event) => {
                                setFirstName(event.target.value);
                                setFirstNameError(true);
                                }}/>
                            {firstNameError && !firstName && <span className="error-message-text">Please enter first name</span>}
                        </div>
                        <div className="breakdiv">

                        </div>
                        <div className="modal-form-control-container last-name-mobile">
                            <input type="text" name="last-name" placeholder="Last Name*" className='modal-form-control' value = {lastName} onInput = {(event) => {
                                setLastName(event.target.value);
                                setLastNameError(true);}}/>
                            {lastNameError && !lastName && <span className="error-message-text">Please enter last name</span>}
                        </div>

                    </div>
                    <div className="modal-form-group">
                        <div className="modal-form-control-container">
                            <PhoneInput
                                international
                                addInternationalOption={false}
                                value={phoneNumber}
                                onChange={handleSetPhoneNumber}
                                defaultCountry='US'
                                onCountryChange={setCountry}
                                placeholder='phonenumber'
                                style={{
                                    flex: 1,
                                    border: "1px solid var(--secondary-text-color)",
                                    borderRadius: "5px",
                                    backgroundColor: "#E1E9F5"
                                }}

                            />
                            {phoneNumberError && !validatePhoneInput() && <span className="error-message-text">Please enter a valid phone number</span>}
                        </div>
                    </div>
                    <div className="modal-form-group">
                        <div className="modal-form-control-container">
                            <input type="text" name="email" placeholder="Email*" className='modal-form-control' value = {email} onInput = {(event) => {
                                setEmail(event.target.value);
                                setEmailError(true)}}/>
                            {emailError && !validateEmail() && <span className="error-message-text">Please enter a valid email</span>}
                        </div>
                    </div>
                    <div className="modal-form-group">
                        <div className={`modal-form-submit ${!disabled ? "": "disabled"}`} onClick = {() => {
                            if(disabled) {
                                console.log("Nothing happens");
                                return;
                            }
                            if(!isCartSubmit) {
                                handleConsultationRequest()
                                //Save consultation type
                            } else {
                                handleCartSubmissionRequest()
                                //Save cart type
                            }
                        }}>
                            <div className="modal-form-submit-message">LET'S CONNECT</div>
                            <div className='modal-form-redirection-icon-container'><img src={redirectIcon} alt="" className="redirection-icon" /></div>
                        </div>
                    </div>
                    <span className='footnote'>WE'LL CONTACT YOU SHORTLY!</span>

                </div>
            </div>}

            {showSubmitAnimation && <div className="thank-you-container">
                <div className='thank-you-animation-container'>
                    <Lottie animationData={tickok} className = "thank-you-tickok" loop autoplay></Lottie>
                </div>
                <div className="thank-you-message">
                        {`Great! We got your ${isCartSubmit? "cart ": ""} info. We will be in touch shortly.`}
                </div>
            </div>}
        </div>
    );
}

export default TalkToUsComponent;