const footer = {
    support:{
        "phone": "(970) 639-7833",
        "email":"hello@usespyder.com",
        "address":`Operational Address: \nBUNDL FINANCIAL TECHNOLOGIES INC,\n2055 LIMESTONE RD STE 200-C\nWILMINGTON, DE 19808`
    },
    menu:{
        Company:{
            "About":"",
            // "Blog":"",
            "Contact Us": "",
            "Pricing": "/pricing",
            // "Testimonials": ""
        },
        // Support:{
        //     "Help Center":"",
        //     "Terms of Service":"",
        //     "Legal": "",
        //     "Privacy Policy": "",
        //     "Status": ""
        // }

    }
    
 }
 
 export default footer
 
 