// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCW2ZzhOBmQEmA6xMgxWyaKqi0wWCZkg6M",

  authDomain: "sage-website-43bd6.firebaseapp.com",

  databaseURL: "https://sage-website-43bd6-default-rtdb.firebaseio.com",

  projectId: "sage-website-43bd6",

  storageBucket: "sage-website-43bd6.appspot.com",

  messagingSenderId: "919575458701",

  appId: "1:919575458701:web:9f13e452f1fefe4ad703c4"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);