// App.js
import React, { useEffect, useState } from 'react';
import './App.css';
import './Css/App.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import 'react-modern-drawer/dist/index.css';
import Modal from "react-modal";
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import ModalContext, { ModalProvider } from './context/ModalProvider';
import ModalCustom from './Components/ModalCustom';
import { CartProvider } from './context/CartProvider';
import { AuthContextProvider } from './context/AuthProvider';
import SlideCart from './pages/SlideCart';
import Cart from './pages/Cart';
import cart_icon from './Assets/cart.png';
import PlansProvider from './context/PlansProvider';
import TalkToUsComponent from './Components/TalkToUs';
import PageProvider from './context/PageProvider';
import FileSystemProvider from './context/FileSystemProvider';

Modal.setAppElement("#modal_element");

function App() {
  const [isCartOpen, setIsCartOpen] = useState(false);

  const openCart = () => {
    setIsCartOpen(true);
  };

  const closeCart = () => {
    setIsCartOpen(false);
  };

  useEffect(() => {

    document.documentElement.style.setProperty(
      "--secondary-bgcolor",
      process.env.REACT_APP_SECONDARY_BGCOLOR
    );
    document.documentElement.style.setProperty(
      "--text-color",
      process.env.REACT_APP_TEXT_COLOR
    );
    document.documentElement.style.setProperty(
      "--text-grey-color",
      process.env.REACT_APP_TEXT_GREY_COLOR
    );
    document.documentElement.style.setProperty(
      "--secondary-text-color",
      process.env.REACT_APP_SECONDARY_TEXT_COLOR
    );
    document.documentElement.style.setProperty(
      "--text-silver-color",
      process.env.REACT_APP_TEXT_SILVER_COLOR
    );
  }, []);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <PlansProvider>
          <CartProvider>
            <PageProvider>
              <FileSystemProvider>
                {/* <BrowserRouter> */}
                <ModalProvider>
                  <Router />
                  <ModalCustom><TalkToUsComponent></TalkToUsComponent></ModalCustom>
                  {/* <div className="floating-cart" onClick={openCart}>
              <img src={cart_icon} className="cart_icon" alt="Cart Icon" />
            </div>

            <SlideCart isOpen={isCartOpen} onClose={closeCart} />  */}
                  <Cart />
                </ModalProvider>
                {/* </BrowserRouter> */}
              </FileSystemProvider>
            </PageProvider>
          </CartProvider>
        </PlansProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
