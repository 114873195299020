import plus from "../Assets/Plus Square.png";
import minusLight from "../Assets/Minus Light.png";
import plusLight from "../Assets/Plus Square light.png";
import minus from "../Assets/Minus.png"
import '../Css/Counter.css';
const CounterComponent = ({
    value,
    setValue,
    min_val,
    max_val
}) => {
    return (  
        <div className="counter-container">
            <div className="count-button" onClick = {() => {
                    if(value !== min_val) {
                        setValue(value -1);
                    }
                }} >
                {value === min_val && <img src={minusLight} alt="" className = {`${value === min_val? "disable": ""} button-img`} />}
                {value !== min_val && <img src={minus} alt="" className = {`${value === min_val? "disable": ""} button-img`} />}
            </div>
            <div className="value-container">{value}</div>
            <div className="count-button" onClick = {() => {
                    if(value !== max_val) {
                        setValue(value +1);
                    }
                }} >
                {value === max_val && <img src={plusLight} alt="" className = {`${value === max_val? "disable": ""} button-img`} /> }
                {value !== max_val && <img src={plus} alt="" className = {`${value === max_val? "disable": ""} button-img`} /> }
            </div>
        </div>
    );
}

export default CounterComponent;