import { useContext, useEffect, useState } from "react";
import { PlansContext } from "../context/PlansProvider";
import SliderRuleComponent from "./SliderRule";
import { FaCheckCircle } from "react-icons/fa";
import '../Css/Pricing.css';
import { debounce, set } from "lodash";
import { useCart } from "../context/CartProvider";
import { useCallback } from "react";
import closeIcon from "../Assets/multiply.png";
import "../Css/PlanComponent.css";
import "../Css/Pricing.css";
import CounterComponent from "./Counter";
import deleteIcon from "../Assets/Recycle Bin.png"
import ValueInputComponent from "./ValueInput";

const PlanComponent = ({
    plan,
    index,
    isCartPlan,
}) => {
    const { allPlans, updatePlan } = useContext(PlansContext);
    const [value, setValue] = useState(plan.value);
    //Incase of baseline increment plans we need to show base threshold as initialized value1
    const [value1, setValue1] = useState(plan.value1);
    const [planAdded, setPlanAdded] = useState(false);
    const {cartPlans, addCartPlan, removeCartPlan, updateCartPlanValue} = useCart();

    const debounce = (func, delay) => {
      let timeout=null;
      return () => {
          if(timeout) clearTimeout(timeout);
  
          timeout=setTimeout(() => {
              func();
          }, delay)
      }
  }

    const calculatePlanPrice = (plan, value, value1) => {
      switch(plan.plan_type) {
        case "threshold_step": {
          let flag = 0;
          for(let price of plan.prices){
            if(price.threshold >= value) {
              plan.plan_price = price.price.match(/\d+/g).join('');
              flag = 1;
              break;
            }
          }
          if(flag === 0) {
            plan.plan_price = plan.prices[plan.prices.length - 1].price + " + Custom"
          }
          break;
        }
        case "baseline_increment": {
          let newPrice = parseFloat(plan.plan_price);
          let initialThreshold = plan.prices[0].threshold;
          let basePrice = parseFloat(plan.prices[0].price.match(/\d+/g).join(''));
          let incrementalPrice = parseFloat(plan.prices[1].price.match(/\d+/g).join(''));
          if(value1 >= initialThreshold) {
            newPrice = basePrice + (value1 - initialThreshold) * incrementalPrice;
          }
          plan.plan_price = newPrice.toFixed(0);
          break;

        }
        case "by_value": {
          //Initial price, ensure that it only contains 1 number
          let newPrice = parseFloat(plan.prices[0].price.match(/\d+/g).join(''));
          let factorValue = isNaN(value)? 0: value;
          newPrice *= factorValue;
          plan.plan_price = newPrice.toFixed(0);
          break;
        }
        case "fixed": {
          break;
        }
        case "by_restricted_value": {
          //Initial price, ensure that it only contains 1 number
          let newPrice = parseFloat(plan.prices[0].price.match(/\d+/g).join(''));
          let factorValue = isNaN(value1)? 0: value1;
          newPrice *= factorValue;
          plan.plan_price = newPrice.toFixed(0);
          break;
        }
        default:
          console.error("Could not calculate price unknown plan type");
      };
      return {plan_price: plan.plan_price, newValue: value, newValue1: value1};
    }

    // useEffect(()=> {
    //   if(plan.value != 1) {
    //     updatePlan(plan.id, value);
    //   }
    // }, []);

    useEffect(() => {
        const {plan_price, newValue, newValue1} = calculatePlanPrice(plan, value, value1)
        updatePlan(plan.id, newValue, newValue1, plan_price);
        updateCartPlanValue(plan.id, newValue, newValue1, plan_price);
    }, [value, value1]);

    const isPlanInCart = (planId) => {
      const planInCart = cartPlans.find((cartPlan) => cartPlan.id === planId);
      return planInCart ? true: false;
    };

    const handleAddPlan = (plan) => {
      addCartPlan(plan);
      setPlanAdded(true);
    };

    const handleRemovePlan = (planId) => {
      removeCartPlan(plan.id);
      setPlanAdded(false);
    }

    const capitalizeFirstLetter = (text) => {
      return text.substring(0, 1).toUpperCase() + text.substring(1);
    }

    const checkPlanValueValidity = () => {
      return plan.plan_type !== "baseline_increment" && isNaN(plan.value) && !isPlanInCart(plan.id);
    }

    return ( 
        <>
         {!isCartPlan && <div key = {"plan_"+index} className="plan">
            <div className="pricing-content-section">
                <div className="plan-name-heading"><h4>{plan.name}</h4></div>
                
                <div className="content">
                    <p className="description">
                      {plan.description}
                      {(plan.plan_type === "threshold_step") && 
                        <b>{`If you had $${plan.value > plan.max_val? (plan.max_val + "+"): plan.value} expenses for the month`}</b>
                      }
                      {(plan.plan_type === "by_value" || plan.plan_type === "by_restricted_value") && 
                        <p><b>Input the number of {plan.prices[0].unit} ranging from {plan.min_val} - {plan.max_val}</b></p>
                      }
                      {(plan.plan_type === "threshold_step") && 
                        <SliderRuleComponent sliderName={"plan_"+index+"_slider"} value = {plan.value} setValue = {setValue} min_val={plan.min_val} max_val={plan.max_val + 0.1 * plan.max_val}></SliderRuleComponent>
                      }
                      {(plan.plan_type === "by_value") && 
                        <ValueInputComponent value = {plan.value} setValue = {setValue} min_val = {plan.min_val} max_val = {plan.max_val}></ValueInputComponent>
                      }
                      {(plan.plan_type === "baseline_increment") && 
                        <>
                        <p><b>{`Base Price: ${plan.prices[0].price} (includes up to ${plan.prices[0].threshold} ${plan.prices[0].unit})`}</b></p>
                        <p><b>{`Additional ${capitalizeFirstLetter(plan.prices[0].singleUnit)} Fee: ${plan.prices[1].price} per ${plan.prices[0].singleUnit} (applies to ${plan.prices[0].unit} exceeding ${plan.prices[0].threshold} )`}</b></p>
                        </>
                      }
                      {(plan.plan_type === "baseline_increment" || plan.plan_type === "by_restricted_value") && 
                        <>
                        <CounterComponent value = {plan.value1} setValue={setValue1} min_val={plan.min_val} max_val={plan.max_val}></CounterComponent>
                        {(plan.plan_type === "baseline_increment") && <p className="price-subtext"><b>{`${plan.prices[0].price} + (${plan.prices[1].price} x number of ${plan.prices[0].unit} exceeding ${plan.prices[0].threshold})`}</b></p>}
                        {(plan.plan_type === "by_restricted_value") && <p className="price-subtext"><b>{`${plan.prices[0].price} x number of ${plan.prices[0].unit}`}</b></p>}
                        </>
                      }
                      {
                        plan.features && plan.features.length !== 0 && <ul className="features">
                          {plan.features.map((feature,i)=>(
                            <li key={"plan_"+index+"_feature_"+i}>{feature}</li>
                          ))}
                        </ul>
                      }
                    </p>
                    
                </div>
            </div>
            <div className="action">
                  <div className={`price`}>
                    {plan.plan_type === "fixed" ?
                    <span className="amount">{plan.prices[0].price}</span>
                    : <span className="amount">{isNaN(plan.plan_price)? '':`$`}{!isNaN(plan.plan_price)? plan.plan_price: (plan.plan_type === "by_value" ? "$0": plan.plan_price)}</span>}

                    {/* <span className="duration">{price.duration}</span> */}
                  </div>
                  <button
                    onClick={() => {
                      if(checkPlanValueValidity() === true){
                        return;
                      }
                      console.debug("planAdded value: ", isPlanInCart());
                      if(isPlanInCart(plan.id) === false){
                        handleAddPlan(plan);
                      }
                      else {
                        handleRemovePlan(plan.id);
                      }
                    }}
                    // className={`add_btn 
                    // ${addedPlans[`${index}_${categoryIndex}`] ? "added" : ""}
                    // `}
                    className={`add_btn ${checkPlanValueValidity() === true ? "disabled-button": ""} ${isPlanInCart(plan.id)? "added": ""}`}
                  >
                    {/* {addedPlans[`${index}_${categoryIndex}`] ? (
                      <>
                        <span><FaCheckCircle className="checked_icon"/></span>
                        <span className="text">Plan Added</span>
                      </>
                    ) : (
                      "Add Plan"
                    )} */}
                    {isPlanInCart(plan.id) ? <>
                          <span><FaCheckCircle className="checked_icon"/></span>
                          <span className="text">Selected</span>
                    </>: "Add Plan" }
                  </button>
                </div>
        </div>}

        {isCartPlan && <div key = {"cart_plan_"+index} className="plan cart-plan-container">
            {/* <div className="pricing-content-section">
                <h4>{plan.name}</h4>
                <div className="content">
                    <p className="description">{plan.description}</p>
                    {
                      plan.features && plan.features.length !== 0 && <ul className="features">
                        {plan.features.map((feature,i)=>(
                          <li key={"cart_plan_"+index+"_feature_"+i}>{feature}</li>
                        ))}
                      </ul>
                    }
                </div>
            </div> */}

            <div className="plan-card">
              <div className="plan-body">
                <div className="plan-heading">
                  <div className="plan-heading-text">
                    {plan.name}
                  </div>
                  {(plan.plan_type === "threshold_step") && 
                        <p className="highlight"><b>{`If you had $${plan.value > plan.max_val? (plan.max_val + "+"): plan.value} expenses for the month`}</b></p>
                  }
                  {(plan.plan_type === "by_value" || plan.plan_type === "by_restricted_value") && 
                        <p className = "highlight"><b>Input the number of {plan.prices[0].unit} ranging from {plan.min_val} - {plan.max_val}</b></p>
                  }
                  {(plan.plan_type === "baseline_increment") && 
                        <>
                        <p className="highlight"><b>{`Base Price: ${plan.prices[0].price} (includes up to ${plan.prices[0].threshold} ${plan.prices[0].unit})`}</b></p>
                        <p className="highlight"><b>{`Additional ${capitalizeFirstLetter(plan.prices[0].singleUnit)} Fee: ${plan.prices[1].price} per ${plan.prices[0].singleUnit} (applies to ${plan.prices[0].unit} exceeding ${plan.prices[0].threshold} )`}</b></p>
                        </>
                  }
                  {(plan.plan_type === "threshold_step") &&
                    <div className="plan-content">
                      <SliderRuleComponent sliderName={"cart_plan_"+index+"_slider"} value = {plan.value} setValue = {setValue} min_val={plan.min_val} max_val={plan.max_val + 0.1 * plan.max_val}></SliderRuleComponent>
                    </div>
                  }

                  {(plan.plan_type === "by_value") &&
                    <ValueInputComponent value = {plan.value} setValue = {setValue} min_val = {plan.min_val} max_val = {plan.max_val}></ValueInputComponent>
                  }

                  {(plan.plan_type === "baseline_increment" || plan.plan_type === "by_restricted_value") && 
                  <>
                    <CounterComponent value = {plan.value1} setValue={setValue1} min_val={plan.min_val} max_val={plan.max_val}></CounterComponent>
                    </>
                  }

                  <div className="plan-price-container">
                    <div className="price-label-text">Price:&nbsp;</div>
                    <div className="price-value-text">{isNaN(plan.plan_price)? '':`$`}{plan.plan_price || "0"}</div>
                  </div>

                </div>
              </div>
              <div className="plan-cancel-container">
                <img src={deleteIcon} alt="remove-plan" className="remove-plan-icon" onClick={() => {removeCartPlan(plan.id)}}/>
              </div>
            </div>
        </div>}
        </> 
    );
}

export default PlanComponent;