import React, { useState,createContext } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {

  const [modalVisible, setModalVisibility] =useState(false);
  const [modalView, setModalView] =useState();
  const [modalCancelable, setModalCancelable] = useState(true);
  const [isCartSubmit, setIsCartSubmit] = useState(false);

  return (
    <ModalContext.Provider value={{ modalVisible, setModalVisibility, modalView, setModalView, modalCancelable, setModalCancelable, isCartSubmit, setIsCartSubmit }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;