const differences ={
    difference1 : {
     "icon": "FaPhoneAlt",
     "title": "One-on-one expert support",
     "description_firstletter":"S",
     "description1":"pyder gives you a dedicated bookkeeper supported by a team of knowledgeable small business experts.",
     "description2":"Get a direct line to our team—professional support is just a few swipes, taps, or clicks away."
    },
    difference2 : {
        "icon" : "FaUserGraduate",
        "title": "Experienced professionals",
        "description_firstletter":"W",
        "description1":"e're trusted by growing businesses because we've made bookkeeping easy and tax season stress free.",
        "description2":"We understand the unique challenges that come with growing a business and have the expertise you need to reach your goals."
    },
    difference3 : {
    "icon" : "FaChartLine",
    "title": "Powerful financial reporting",
    "description_firstletter":"S",
    "description1":"ee how your business is doing in past. months and in real-time, so you can master your spending and make smarter decisions with your money.",
    "description2":"We support tiny startups and large companies, so no matter how your needs evolve, we've got you covered."
    }
 }
 
 export default differences
 
 