import React, { useContext, useEffect, useState } from 'react'
import '../../Css/ProfilePages/Profile.css'
import { AuthContext, UserAuth } from '../../context/AuthProvider';
import userApis from '../../Apis/UserApi';
import Modal from "react-modal";

const Profile = ({ }) => {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [stateOfCity, setStateOfCity] = useState("");
  const [stateOfCityError, setStateofCityError] = useState(false);
  const [zipcode, setZipcode] = useState("");
  const [zipcodeError, setZipcodeError] = useState(false);
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [disableUpdateButton, setDisableUpdateButton] = useState(true);
  const { user, setUser } = UserAuth();
  const [profileUpdated, setProfileUpdated] = useState(false);

  const customStyles = {
    content: {
      top: "5%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "91%",
      maxWidth: "35rem",
      padding: 0,
    },
    overlay: {
      background: "transparent",
      zIndex: 999
    }
  };

  const checkIfAllFieldsAreFilled = (value) => {
    if (value && firstName && lastName && email && address && phoneNumber && city && stateOfCity && zipcode && country) {
      setDisableUpdateButton(false);
    } else {
      setDisableUpdateButton(true);
    }
  }

  const setPhoneNumberDigits = (phoneNumber) => {
    if(phoneNumber) {
      if(validatePhoneNumber(phoneNumber)) {
        setPhoneNumber(phoneNumber);
      }
    } else {
      setPhoneNumber(phoneNumber);
    }
  }

  const validatePhoneNumber = (phoneNumber) => {
    // Regular expression to match only digits
    const regex = /^[0-9]+$/;
    // Test the input string against the regular expression
    return regex.test(phoneNumber);
}

  const updateUserProfile = async () => {
    setDisableUpdateButton(true);
    const tempUser = {
      "first_name": firstName,
      "last_name": lastName,
      "address": address,
      "phone_number": phoneNumber,
      "city": city,
      "state": stateOfCity,
      "zip_code": zipcode,
      "country": country
    }

    const updatedUser = await userApis.updateUserById(user.Id, tempUser);
    setUser((prevUser) => {
      return {...prevUser, ...updatedUser}
    });
    setDisableUpdateButton(false);
    setProfileUpdated(true);
    setTimeout(()=> {
      setProfileUpdated(false);
    }, 2000);
  }
  useEffect(() => {
    if (user.first_name) {
      setFirstName(user.first_name);
      setFirstNameError(true);
    }
    if (user.last_name) {
      setLastName(user.last_name);
      setLastNameError(true);
    }
    if (user.email) {
      setEmail(user.email);
    }
    if (user.address) {
      setAddress(user.address);
      setAddressError(true);
    }
    if (user.phone_number) {
      setPhoneNumber(user.phone_number);
      setPhoneNumberError(true);
    }
    if (user.city) {
      setCity(user.city);
      setCityError(true);
    }
    if (user.state) {
      setStateOfCity(user.state);
      setStateofCityError(true);
    }
    if (user.zip_code) {
      setZipcode(user.zip_code);
      setZipcodeError(true);
    }
    if (user.country) {
      setCountry(user.country);
      setCountryError(true);
    }
  }, [user]);
  return (
    <div className='profile-section'>
      <h3>Update Personal Information</h3>
      <div className='profile-input-section'>
        <div className='profile-input'>
          <h5>First name*</h5>
          <input placeholder='First Name' value={firstName} onChange={(event) => {
            setFirstName(event.target.value);
            setFirstNameError(true);
            checkIfAllFieldsAreFilled(event.target.value);
          }} required />
          {firstNameError && !firstName && <span className='input-validation-error'>Please enter first name</span>}
        </div>
        <div className='profile-input'>
          <h5>Last name*</h5>
          <input placeholder='Last Name' value={lastName} onChange={(event) => {
            setLastName(event.target.value);
            setLastNameError(true);
            checkIfAllFieldsAreFilled(event.target.value);
          }} required />
          {lastNameError && !lastName && <span className='input-validation-error'>Please enter last name</span>}
        </div>
      </div>
      <div className='profile-input-section'>
        <div className='profile-input address'>
          <h5>Email Address*</h5>
          <input className="non-editable" placeholder='Email Address' value={email} required readOnly />
        </div>

        <div className='profile-input address'>
          <h5>Phone Number*</h5>
          <input placeholder='Phone Number' value={phoneNumber} onChange={(event) => {
            setPhoneNumberDigits(event.target.value);
            setPhoneNumberError(true);
            checkIfAllFieldsAreFilled(event.target.value);
          }} required />
          {phoneNumberError && !phoneNumber && <span className='input-validation-error'>Please enter valid phonenumber</span>}
        </div>
      </div>
      <div className='profile-input-section'>
        <div className='profile-input  address'>
          <h5>Address*</h5>
          <input placeholder='Address' value={address} onChange={(event) => {
            setAddress(event.target.value);
            setAddressError(true);
            checkIfAllFieldsAreFilled(event.target.value);
          }} required />
          {addressError && !address && <span className='input-validation-error'>Please enter valid address</span>}
        </div>
        <div className='profile-input'>
          <h5>City*</h5>
          <input placeholder='city' value={city} onChange={(event) => {
            setCity(event.target.value);
            setCityError(true);
            checkIfAllFieldsAreFilled(event.target.value);
          }} required />
          {cityError && !city && <span className='input-validation-error'>Please enter city</span>}
        </div>
        <div className='profile-input'>
          <h5>State/Province*</h5>
          <input placeholder='province' value={stateOfCity} onChange={(event) => {
            setStateOfCity(event.target.value);
            setStateofCityError(true);
            checkIfAllFieldsAreFilled(event.target.value);
          }} required />
          {stateOfCityError && !stateOfCity && <span className='input-validation-error'>Please enter state</span>}
        </div>
      </div>
      <div className='profile-input-section'>
        <div className='profile-input'>
          <h5>Zipcode*</h5>
          <input placeholder='zipcode' value={zipcode} onChange={(event) => {
            setZipcode(event.target.value);
            setZipcodeError(true);
            checkIfAllFieldsAreFilled(event.target.value);
          }} required />
          {zipcodeError && !zipcode && <span className='input-validation-error'>Please enter zipcode</span>}
        </div>
        <div className='profile-input'>
          <h5>Country*</h5>
          <input placeholder='country' value={country} onChange={(event) => {
            setCountry(event.target.value);
            setCountryError(true);
            checkIfAllFieldsAreFilled(event.target.value);
          }} required />
          {countryError && !country && <span className='input-validation-error'>Please enter country</span>}
        </div>
        <div className='profile-input submit-update-btn'>
          <h5></h5>
          <button className='profile-update' disabled={disableUpdateButton} onClick={() => {
            updateUserProfile();
          }}>Update</button>
        </div>

      </div>
      <Modal isOpen={profileUpdated} onRequestClose={() => {
        setProfileUpdated(false);
      }}
        style={customStyles}>
        <div className='loader-container'>
          Profile updated successfully
        </div>
      </Modal>
    </div>
  )
}

export default Profile
