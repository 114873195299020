import axios from "axios";
const FormSparkApi = () => {
    const submitConsultationForm = async(user) => {
        try {
            let data = {
                id: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber
            }
    
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://submit-form.com/${process.env.REACT_APP_FORMSPARK_FORM_ID}`,
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : {
                    type: "consultation",
                    ...data
                }
            }
    
            await axios(config);
            console.log(`Consultation Form submitted`)
        } catch (error) {
            console.error(`Consultation form submit failed due to ${error.message? error.message: error}`);
        }
    }

    const submitCartForm = async(cart, user) => {
        try {
            let data = {
                id: user.userId,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phoneNumber,
                cart: JSON.stringify(cart)
            }
    
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `https://submit-form.com/${process.env.REACT_APP_FORMSPARK_FORM_ID}`,
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : {
                    type: "cart-order",
                    ...data
                }
            }
    
            await axios(config);
            console.log(`Cart Form submitted`)
        } catch (error) {
            console.error(`Cart form submit failed due to ${error.message? error.message: error}`);
        }
    }

    return {
        submitConsultationForm,
        submitCartForm
    }
}

const formSparkApi = FormSparkApi();
export default formSparkApi;